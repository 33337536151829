import { DownloadOutlined, ImportOutlined, UploadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput.styles';
import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { saveRecord } from '@app/utils/APIUtils';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { notificationController } from '@app/controllers/notificationController';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN, API_BASE_URL } from '@app/constants';

const ImportRegistrationRecord = () => {
  const [file, setFile] = useState();
  const [recordSet, setRecordSet] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [searchedText, setSearchedText] = useState('');
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDownload = () => {
    fetch(API_BASE_URL + '/public/customer/v1/registered/download', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
      },
    })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      }
      throw new Error('Network response was not ok.');
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'reg_customers.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => console.error('Error downloading the file:', error));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const data = results.data.map((record, index) => ({
            serial: index + 1,
            customerId: record.customerId,
            customerName: record.customerName,
            mobileNumber: record.mobileNumber,
            hajYearId: record.hajYearId,
            hajSerialNo: record.hajSerialNo,
            registrationDate: record.registrationDate,
          }));
          setRecordSet(data);
          setDisabled(false);
        },
      });
    } else {
      notificationController.warning({ message: 'Please choose a file' });
      setRecordSet([]);
      setDisabled(true);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const response = await saveRecord(recordSet, '/public/customer/v1/registered/import/csv');
      if (response) {
        notificationController.success({ message: 'Registration uploaded.' });
        navigate('/registration/list');
      }
    } catch (error) {
      notificationController.error({ message: `Failed! ${error.message}` });
    }
  };

  const columns = [
    {
      title: 'Index',
      dataIndex: 'serial',
      key: 'serial',
      width: '10%',
    },
    {
      title: 'CID',
      dataIndex: 'customerId',
      key: 'customerId',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'customerName',
      key: 'customerName',
      width: "25%",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.customerName).toLowerCase().includes(value.toLowerCase()) ||
          String(record.mobileNumber).toLowerCase().includes(value.toLowerCase()) ||
          String(record.hajSerialNo).toLowerCase().includes(value.toLowerCase()) 
        );
      },
    },
    {
      title: 'Mobile',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      width: "10%",
    }, 
    /* {
      title: 'Hajj Year',
      dataIndex: 'serialNo',
      key: 'serialNo',
      width: "10%",
    }, */
    {
      title: 'Serial No',
      dataIndex: 'hajSerialNo',
      key: 'hajSerialNo',
      width: "10%",
    },
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: "15%",
    },
  ];

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title="Import Customer Registration CSV"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton
            type="info"
            className="btn btn-dark"
            onClick={handleDownload}
            size="small"
            icon={<DownloadOutlined />}
          >
            Download
          </BaseButton>
        }
      >
        <Form id="fileUploadForm" style={{ position: 'absolute', left: '35%' }}>
          <Form.Item>
            <Input type="file" accept=".csv" onChange={handleOnChange} allowClear />
          </Form.Item>

          <BaseSpace style={{ position: 'absolute', left: '15%' }}>
            <BaseButton type="primary" onClick={handleOnSubmit} size="small" icon={<ImportOutlined />}>
              Import CSV
            </BaseButton>
            <BaseButton type="info" onClick={() => navigate('/registration/list')} size="small">
              Cancel
            </BaseButton>
          </BaseSpace>
        </Form>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '7rem' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onSearch={setSearchedText}
              onChange={(e) => setSearchedText(e.target.value)}
              style={{ width: 230, marginBottom: 10 }}
              allowClear
              size="small"
            />
          </ST.InputsWrapper>
          <div>
            <BaseButton
              type="primary"
              size="small"
              icon={<UploadOutlined />}
              disabled={disabled}
              onClick={handleUpload}
            >
              Upload
            </BaseButton>
          </div>
        </div>

        <BaseTable
          columns={columns}
          dataSource={recordSet}
          bordered
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          scroll={{ x: true }}
        />
      </S.Card>
    </div>
  );
};

export default ImportRegistrationRecord;
