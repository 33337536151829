import React, { useState, useEffect } from 'react';
import { Input, Select, Form, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { saveRecord, searchRecord, getRecords } from '@app/utils/APIUtils';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';

const { Option } = Select;

const CustomerPackageAdd = () => {
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [recordSet, setRecordSet] = useState([]);
  const [packageRecSet, setPackageRecSet] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const [customerId, setCustomerId] = useState([]);
  const [packageId, setPackageId] = useState([]);
  const [searchForm] = Form.useForm();
  const [saveForm] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getRecords('/setup/v1/services/all')
      .then((response) => {
        const sortedServices = response
          .map((item) => ({ value: item.id, label: item.ServiceName }))
          .sort((a, b) => {
            if (a.label === 'Hajj') return -1;
            if (b.label === 'Hajj') return 1;
            return a.label.localeCompare(b.label);
          });
        setServiceList(sortedServices);
        if (sortedServices.length > 0) {
          setSelectedService(sortedServices[0].value);
          handleServiceChange(sortedServices[0].value);
          saveForm.setFieldsValue({ serviceId: sortedServices[0].value });
        }
      })
      .catch((error) => notificationController.error({ message: `Failed to fetch services: ${error.message}` }))
      .finally(() => setLoading(false));
  }, []);

  const handleServiceChange = (serviceId) => {
    setSelectedService(serviceId);
    getRecords(`/setup/v1/packages/${serviceId}/service`)
      .then((response) => setPackageRecSet(response))
      .catch(() => setPackageRecSet([]));
  };

  const handleSearch = () => {
    setLoading(true);
    searchForm.validateFields().then((values) => {
      if (
        values.customerName === undefined &&
        values.mobileNumber === undefined &&
        values.groupNumber === undefined &&
        values.nationalId === undefined &&
        values.passportNo === undefined &&
        values.tags === undefined
      ) {
        notificationController.warning({
          message: 'Please enter one filtering criteria!',
        });
      }
      const searchRequest = {
        customerName: values.customerName === '' ? null : values.customerName,
        mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
        groupNumber: values.groupNumber === '' ? null : values.groupNumber,
        nationalId: values.nationalId === '' ? null : values.nationalId,
        passportNo: values.passportNo === '' ? null : values.passportNo,
        tags: values.tags === '' ? null : values.tags,
      };
      searchRecord(searchRequest, '/customer/v1/packages/search/customer')
        .then((response) => {
          setRecordSet(response);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    });
  };

  const handleSubmit = () => {
    saveForm.validateFields().then((values) => {
      const saveRequest = {
        customerId,
        packageId,
        packageFinalPrice: values.packageFinalPrice,
      };

      if (!customerId.length) {
        notificationController.warning({ message: 'Please select any customer!' });
      } else if (!packageId.length) {
        notificationController.warning({ message: 'Please select a package!' });
      } else {
        saveRecord(saveRequest, '/customer/v1/packages')
          .then((response) => {
            if (response.statusCode === 100) {
              notificationController.success({ message: 'Customer added to package.' });
              navigate('/m_customer_package/list');
            } else if (response.statusCode === 101) {
              notificationController.warning({ message: 'Customer already added to package!' });
            }
          })
          .catch((error) => notificationController.error({ message: `Failed: ${error.message}` }));
      }
    });
  };

  const customerColumns = [
    { title: 'Name', dataIndex: 'customerName', key: 'customerName' },
    { title: 'Mobile', dataIndex: 'mobileNumber', key: 'mobileNumber' },
    {
      title: 'Group',
      dataIndex: 'groupNumber',
      key: 'groupNumber',
      render: (_, { groupNumber }) => {
        let color = groupNumber ? '#108ee9' : '';
        return groupNumber ? (
          <Tag color={color} key={groupNumber}>
            {groupNumber}
          </Tag>
        ) : null;
      },
    },
  ];

  const packageColumns = [
    {
      title: 'Package Name',
      dataIndex: 'packageName',
      key: 'packageName',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.packageName).toLowerCase().includes(value.toLowerCase());
      },
    },
    { title: 'Duration', dataIndex: 'duration', key: 'duration' },
    { title: 'Price', dataIndex: 'price', key: 'price' },
  ];

  const customerSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCustomerId(selectedRows.map((item) => item.customerId));
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  const pkgSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPackageId(selectedRows.map((item) => item.packageId));
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  const onCancelForm = () => {
    navigate('/m_customer_package/list');
  };

  return (
    <BaseCard id="validation form" title="Customer Package / Add" padding="1.25rem">
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={20} xl={12}>
          <BaseCard id="label" padding="1.25rem">
            <div style={{ marginBottom: 10 }}>
              <span style={{ color: '#01509A', fontWeight: 600 }}>Search Customer By (any one field)</span>
            </div>
            <Form
              form={searchForm}
              onFinish={handleSearch}
              className="search-form"
              name="basic"
              labelCol={{ span: 6 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <BaseRow gutter={[0, 0]}>
                <BaseCol xs={24} sm={12} xl={12}>
                  <Form.Item label="Mobile" name="mobileNumber" className="ant-latest-form-item">
                    <Input
                      placeholder="Enter mobile number"
                      onKeyDown={(e) => handleEnter(e)}
                      className="ant-latest-form-item"
                    />
                  </Form.Item>
                  <Form.Item label="Name" name="customerName" className="ant-latest-form-item">
                    <Input
                      placeholder="Enter customer name"
                      onKeyDown={(e) => handleEnter(e)}
                      className="ant-latest-form-item"
                    />
                  </Form.Item>
                  <Form.Item label="Group" name="groupNumber" className="ant-latest-form-item">
                    <Input
                      placeholder="Enter group number"
                      onKeyDown={(e) => handleEnter(e)}
                      className="ant-latest-form-item"
                    />
                  </Form.Item>
                </BaseCol>
                <BaseCol xs={24} sm={12} xl={12}>
                  <Form.Item label="PP No" name="passportNo" className="ant-latest-form-item">
                    <Input
                      placeholder="Enter passport number"
                      onKeyDown={(e) => handleEnter(e)}
                      className="ant-latest-form-item"
                    />
                  </Form.Item>
                  <Form.Item label="NID" name="nationalId" className="ant-latest-form-item">
                    <Input
                      placeholder="Enter national ID"
                      onKeyDown={(e) => handleEnter(e)}
                      className="ant-latest-form-item"
                    />
                  </Form.Item>
                  <Form.Item label="Tags" name="tags" className="ant-latest-form-item">
                    <Input
                      placeholder="Enter tags"
                      onKeyDown={(e) => handleEnter(e)}
                      className="ant-latest-form-item"
                    />
                  </Form.Item>
                </BaseCol>
                <BaseCol xs={24} sm={12} xl={12}>
                  <Form.Item>
                    <BaseRow gutter={[10, 10]} wrap={false} style={{ justifyContent: 'space-between' }}>
                      <BaseCol span={12} xs={12} sm={12} xl={12}>
                        <BaseButton block type="primary" htmlType="submit" size="small">
                          Search
                        </BaseButton>
                      </BaseCol>
                      <BaseCol span={12} xs={12} sm={12} xl={12}>
                        <BaseButton block type="ghost" onClick={() => searchForm.resetFields()} size="small">
                          Reset
                        </BaseButton>
                      </BaseCol>
                    </BaseRow>
                  </Form.Item>
                </BaseCol>
              </BaseRow>
            </Form>
            <BaseTable
              style={{ marginTop: 20 }}
              rowSelection={{
                type: 'checkbox',
                ...customerSelection,
              }}
              columns={customerColumns}
              dataSource={recordSet}
              pagination={{ pageSize: 5 }}
              scroll={{ x: true }}
              rowKey={(record) => record.customerId}
              bordered
            />
          </BaseCard>
        </BaseCol>
        <BaseCol xs={24} sm={12} xl={12}>
          <Form
            form={saveForm}
            onFinish={handleSubmit}
            className="customer-pkg"
            name="basic"
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="horizontal"
          >
            <Form.Item
              label="Service"
              name="serviceId"
              rules={[{ required: true, message: 'Service is required!' }]}
              className="ant-latest-form-item"
            >
              <Select onChange={handleServiceChange} placeholder="Select service" size="small" style={{ width: 250 }}>
                {serviceList.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <BaseCard id="packages" padding="1.25rem">
                <div className="search-box">
                  <ST.InputsWrapper>
                    <SearchInput
                      placeholder="Search package..."
                      onChange={(e) => {
                        setSearchedText(e.target.value);
                        handleServiceChange(selectedService);
                      }}
                      style={{
                        width: 230,
                      }}
                      allowClear
                      size="small"
                    />
                  </ST.InputsWrapper>
                </div>
                <BaseTable
                  style={{ marginTop: 10 }}
                  rowSelection={{
                    type: 'radio',
                    ...pkgSelection,
                  }}
                  columns={packageColumns}
                  dataSource={packageRecSet}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: true }}
                  rowKey={(record) => record.packageId}
                  bordered
                />
              </BaseCard>
            </Form.Item>
            <Form.Item
              label="Package Final Price"
              name="packageFinalPrice"
              rules={[
                { required: true, message: 'Final price is required!' },
                { pattern: /^\d*$/, message: 'Only numbers allowed' },
              ]}
            >
              <Input
                addonAfter="BDT"
                placeholder="Enter package final price"
                className="ant-latest-form-item"
                size="small"
                style={{ width: 250 }}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 7,
                span: 9,
                style: { marginTop: 20 },
              }}
            >
              <BaseButtonsGroup onCancel={onCancelForm} loading={loading}/>
            </Form.Item>
          </Form>
        </BaseCol>
      </BaseRow>
    </BaseCard>
  );
};

export default CustomerPackageAdd;
