import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

const PDFDownloader = ({ leftTitle, rightTitle, subTitle, pdfRecordSet, reportColumns, setTableLoading }) => {
  // Function to generate and download the PDF
  const downloadPDF = () => {
    const doc = new jsPDF('landscape'); // Set orientation to landscape

    setTableLoading(true);
    try {
      // Page settings
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;

      // Define the header and footer on every page
      const addHeaderFooter = (pageNumber) => {
        doc.setFontSize(16);
        doc.text(leftTitle, 14, 20); // Left-aligned heading

        doc.setFontSize(10);
        doc.text(`${rightTitle} ${new Date().toLocaleDateString()}`, pageWidth - 20, 20, { align: 'right' }); // Right-aligned print date

        doc.setFontSize(12);
        doc.text(subTitle, 14, 30); // Left-aligned agency name

        // Add Footer
        doc.setFontSize(10);
        doc.text('Powered by: Hajlink', 14, pageHeight - 10); // Left-aligned footer text
        const page = `Page ${pageNumber}`;
        doc.text(page, pageWidth - 20, pageHeight - 10, { align: 'right' }); // Right-aligned page number
      };

      let currentPage = 1; // Track the page number

      // Add header to the first page
      addHeaderFooter(currentPage);

      // Render the table
      doc.autoTable({
        columns: reportColumns,
        startY: 35, // Start after the header
        margin: { top: 35 }, // Adjust the margin if needed
        pageBreak: 'auto', // Enable automatic page breaks
        head: [reportColumns],
        body: pdfRecordSet,
        styles: { fontSize: 8 },
        headStyles: { fillColor: [22, 160, 133] }, // Green header
        didDrawPage: (data) => {
          // Add header and footer for subsequent pages\
          const pageNumber = data.pageNumber;
          addHeaderFooter(pageNumber);
        },
      });

      // Save the PDF
      doc.save(`${subTitle || 'report'}.pdf`);

      setTimeout(() => {
        setTableLoading(false);
      }, 3000); // 3000ms = 3 seconds
    } catch (error) {
      notificationController.error({ message: 'Error exporting PDF.' });
      setTableLoading(false);
    }
  };

  return (
    <Button type="primary" className="btn" size="small" onClick={downloadPDF} icon={<ExportOutlined />}>
      Export PDF
    </Button>
  );
};

export default PDFDownloader;
