import React, { useState, useEffect, useCallback } from 'react';
import { getRecordSet, getRecordById, getRecords } from '@app/utils/APIUtils';
import { Modal, Descriptions, Tag, Badge } from 'antd';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { HomeOutlined, PlusCircleOutlined, RocketOutlined } from '@ant-design/icons';
import { LIST_SIZE } from '@app/constants';
import debounce from 'lodash.debounce';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import moment from 'moment';
import PDFDownloader from '@app/components/pdf-downloader/PDFDownloader';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PackagePreferencesList = () => {
  const [recordSet, setRecordSet] = useState([]);
  const [pdfRecordSet, setPdfRecordSet] = useState([]);
  const [agencyName, setAgencyName] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [visible, setVisible] = useState(false);
  const [viewRecord, setViewRecord] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(LIST_SIZE);
  const [totalElements, setTotalElements] = useState(0);
  const navigate = useNavigate();

  const loadRecords = useCallback(
    debounce((searchedText, page, size = LIST_SIZE) => {
      setLoading(true);
      getRecordSet(page, size, '/customer/package/v1/preferences?page=', searchedText)
        .then((response) => {
          setRecordSet(response.content);
          setPage(response.page);
          setSize(response.size);
          setTotalElements(response.totalElements);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  //Load export as PDF records
  const loadPDFRecords = () => {
    getRecords('/customer/package/v1/preferences/all')
      .then((response) => {
        setPdfRecordSet(response);
      })
      .catch((error) => {
        notificationController.error({ message: 'Failed! ' + error.message });
      });

    const username = localStorage.getItem('username'); // Get username from local storage
    // Get user details by username
    getRecords(`/sa/users/${username}`)
      .then((response) => {
        setAgencyName(response.agentName);
      })
      .catch((error) => {
        notificationController.error({ message: 'Failed! ' + error.message });
      });
  };

  useEffect(() => {
    loadRecords(searchedText, page, size);
    loadPDFRecords();
  }, [searchedText, page, size, loadRecords]);

  const save = () => navigate('/package_preferences/new');

  const edit = (id) => navigate(`/package_preferences/edit/${id}`);

  /* const remove = async (id) => {
    Modal.confirm({
      title: 'Are you sure you want to Delete?',
      onOk: async () => {
        try {
          await deleteItemByID(id, '/customer/package/v1/preferences/');
          setRecordSet(recordSet.filter((record) => record.pkgPrefId !== id));
          notificationController.success({ message: 'Package preferences deleted.' });
        } catch (error) {
          notificationController.error({ message: 'Error deleting record.' });
        }
      },
    });
  }; */

  const view = async (id) => {
    setVisible(true);
    try {
      const record = await getRecordById('/customer/package/v1/preferences/', id);
      setViewRecord(record);
    } catch (error) {
      notificationController.error({ message: 'Error fetching record details.' });
    }
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: 'Group No',
      dataIndex: 'groupNumber',
      render: (_, { groupNumber }) => {
        let color = groupNumber ? '#108ee9' : '';
        return groupNumber ? (
          <Tag color={color} key={groupNumber}>
            {groupNumber}
          </Tag>
        ) : null;
      },
    },
    { title: 'Name', dataIndex: 'customerName', filteredValue: searchedText },
    { title: 'Total Pax', dataIndex: 'totalPerson' },
    { title: 'Package Name', dataIndex: 'packageName' },
    { title: 'Duration', dataIndex: 'duration' },
    { title: 'Room Type', dataIndex: 'roomPreference' },
    { title: 'Makkah Hotel', dataIndex: 'makkahHotel' },
    { title: 'Madinah Hotel', dataIndex: 'madinahHotel' },
    { title: 'Remarks', dataIndex: 'remarks' },
    {
      title: 'Action',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton size="small" type="primary" onClick={() => view(record.pkgPrefId)}>
            View
          </BaseButton>
          <BaseButton severity="info" size="small" onClick={() => edit(record.pkgPrefId)}>
            Edit
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  //Download records as PDF section
  // Define title and sub-title
  const leftTitle = agencyName || 'Agency Name'; // Fallback if MyUnit is empty
  const rightTitle = 'Printed on:';
  const subTitle = 'Package Preference';
  //Pdf report column
  const reportColumns = [
    'SL',
    'Group No',
    'Name',
    'Total Pax',
    'Package Name',
    'Duration',
    'Room Type',
    'Makkah Hotel',
    'Madinah Hotel',
    'Remarks',
  ];

  //Map pdfRecords
  const pdfRecords = pdfRecordSet.map((data, index) => [
    index + 1,
    data.groupNumber,
    data.customerName,
    data.totalPerson,
    data.packageName,
    data.duration,
    data.roomPreference,
    data.makkahHotel,
    data.madinahHotel,
    data.remarks,
  ]);


  // Export PDF for Hotel requisition
  const handleHotelReqExportPDF = async () => {
    const doc = new jsPDF('landscape');
    setLoading(true);
    try {
      const response = await getRecords('/customer/package/v1/preferences/hotel/requisition/list');

      // Page settings
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
  
      // Function to add header
      const addHeaderFooter = (pageNumber) => {
        doc.setFontSize(16);
        doc.text(agencyName, 14, 20); // Left-aligned heading
  
        doc.setFontSize(10);
        doc.text(`Printed on: ${new Date().toLocaleDateString()}`, pageWidth - 20, 20, { align: 'right' }); // Right-aligned print date
  
        doc.setFontSize(12);
        doc.text('Hotel requisition for Hajj 1446', 14, 30); // Left-aligned subheading
  
        // Add a border (horizontal line) below the subheading
        doc.setDrawColor(0); // Black line color
        doc.setLineWidth(0.5); // Line width
        doc.line(14, 35, pageWidth - 14, 35); // Horizontal line

        // Add footer
        doc.setFontSize(10);
        doc.text('Powered by: Hajlink', 14, pageHeight - 10); // Left-aligned footer text
        doc.text(`Page ${pageNumber}`, pageWidth - 20, pageHeight - 10, { align: 'right' }); // Right-aligned page number
      };
  
      let currentPage = 1; // Track the page number
      
      // Add header to the first page
      addHeaderFooter(currentPage);

      // Table data
      const tableColumns = [
        'SL',
        'Package',
        'Duration',
        '5-6 Pax/Room',
        '4-5 Pax/Room',
        '4 Pax/Room',
        '3 Pax/Room',
        '2 Pax/Room',
      ];

      const tableRows = response.map((data, index) => [
        index + 1,
        data.packageName,
        `${data.durationLabel} / ${data.numberOfDays}`,
        data.fiveSixPaxRoom,
        data.fourFivePaxRoom,
        data.fourPaxRoom,
        data.threePaxRoom,
        data.twoPaxRoom,
      ]);

      // Render the table
      doc.autoTable({
        columns: tableColumns,
        startY: 38, // Start after the header
        margin: { top: 38 }, // Adjust the margin if needed
        pageBreak: 'auto', // Enable automatic page breaks
        head: [tableColumns],
        body: tableRows,
        styles: { fontSize: 8 },
        headStyles: { fillColor: [22, 160, 133] }, // Green header
        didDrawPage: (data) => {
          // Add header and footer for subsequent pages\
          const pageNumber = data.pageNumber;
          addHeaderFooter(pageNumber);
        },
      });

      // Save PDF
      doc.save('Hotel_requisition.pdf');
      setTimeout(() => {
        setLoading(false);
      }, 3000); // 3000ms = 3 seconds
    } catch (error) {
      notificationController.error({ message: 'Error exporting PDF.' });
      setLoading(false);
    }
  };

  // Export PDF for Flight requisition
  const handleFlightReqExportPDF = async () => {
    const doc = new jsPDF('landscape');
    setLoading(true);
    try {
      const response = await getRecords('/customer/package/v1/preferences/flight/requisition/list');
  
      // Page settings
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
  
      // Function to add header
      const addHeaderFooter = (pageNumber) => {
        doc.setFontSize(16);
        doc.text(agencyName, 14, 20); // Left-aligned heading
  
        doc.setFontSize(10);
        doc.text(`Printed on: ${new Date().toLocaleDateString()}`, pageWidth - 20, 20, { align: 'right' }); // Right-aligned print date
  
        doc.setFontSize(12);
        doc.text('Flight requisition for Hajj 1446', 14, 30); // Left-aligned subheading
  
        // Add a border (horizontal line) below the subheading
        doc.setDrawColor(0); // Black line color
        doc.setLineWidth(0.5); // Line width
        doc.line(14, 35, pageWidth - 14, 35); // Horizontal line

        // Add footer
        doc.setFontSize(10);
        doc.text('Powered by: Hajlink', 14, pageHeight - 10); // Left-aligned footer text
        doc.text(`Page ${pageNumber}`, pageWidth - 20, pageHeight - 10, { align: 'right' }); // Right-aligned page number
      };
  
      let currentPage = 1; // Track the page number
      
      // Add header to the first page
      addHeaderFooter(currentPage);
  
      // Define table columns
      const tableColumns = ['SL', 'Group No', 'Name', 'Date of Birth', 'Passport', 'Passport Expiry Date'];
  
      // Group the data by the specified field
      const groupedData = response.reduce((groups, item) => {
        const groupKey = `${item.duration} (${item.startDate ? moment(item.startDate).format('DD-MMM-YYYY') : ''} ${
          item.endDate ? 'to ' + moment(item.endDate).format('DD-MMM-YYYY') : ''
        })`;
  
        if (!groups[groupKey]) {
          groups[groupKey] = [];
        }
        groups[groupKey].push(item);
        return groups;
      }, {});
  
      let yOffset = 45; // Initial Y offset for content
  
      // Loop through each group
      Object.entries(groupedData).forEach(([group, groupData], groupIndex) => {
        // Add group header
        doc.setFontSize(12);
        doc.text(`Flight ${groupIndex + 1}: ${group}`, 14, yOffset);
        yOffset += 5;
  
        // Prepare table rows for the group
        const tableRows = groupData.map((item, index) => [
          index + 1,
          item.groupNumber,
          item.customerName,
          item.dateOfBirth,
          item.passportNo,
          item.passportExpiry,
        ]);
  
        // Add table for the group
        doc.autoTable({
          startY: yOffset,
          head: [tableColumns],
          body: tableRows,
          margin: { top: 10 },
          theme: 'grid',
          didDrawPage: (data) => {
            // Add header and footer for subsequent pages\
            const pageNumber = data.pageNumber;
            addHeaderFooter(pageNumber);
          },
        });
  
        // Update Y offset for the next group
        yOffset = doc.lastAutoTable.finalY + 10;
  
        // If Y offset exceeds the page height, add a new page
        if (yOffset > pageHeight - 20) {
          doc.addPage();
          yOffset = 20;
        }
      });
  
      // Save the generated PDF
      doc.save('Flight_requisition.pdf');

      setTimeout(() => {
        setLoading(false);
      }, 3000); // 3000ms = 3 seconds

    } catch (error) {
      notificationController.error({ message: 'Error exporting PDF.' });
      setLoading(false);
    }
  };
  

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title="Package Preferences List"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton type="info" className="btn btn-dark" onClick={save} size="small" icon={<PlusCircleOutlined />}>
            Add
          </BaseButton>
        }
      >
        <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onChange={(e) => {
                setSearchedText(e.target.value);
                loadRecords(e.target.value);
              }}
              style={{ width: 230, marginBottom: 10 }}
              allowClear
              size="small"
            />
          </ST.InputsWrapper>
          <div className="btn">
            <BaseSpace>
              <BaseButton
                type="primary"
                className="btn"
                size="small"
                onClick={handleHotelReqExportPDF}
                icon={<HomeOutlined />}
              >
                Hotel Requisition
              </BaseButton>

              <BaseButton
                type="primary"
                className="btn"
                size="small"
                onClick={handleFlightReqExportPDF}
                icon={<RocketOutlined />}
              >
                Flight Requisition
              </BaseButton>

              <PDFDownloader
                leftTitle={leftTitle}
                rightTitle={rightTitle}
                subTitle={subTitle}
                pdfRecordSet={pdfRecords}
                reportColumns={reportColumns}
                setTableLoading={setLoading} // Pass loading state
              />
            </BaseSpace>
          </div>
        </div>
        <BaseTable
          columns={columns}
          dataSource={recordSet}
          pagination={{
            pageSize: size,
            total: totalElements,
            showTotal: (total) => (
              <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {total} records</span>
            ),
            onChange: (newPage) => setPage(newPage - 1),
            /* pageSizeOptions: ['10', '20', '30', '50'], // Options for page size
            showSizeChanger: true, // Show the size changer
            onShowSizeChange: (current, size) => setSize(size), // Handle page size change */
          }}
          rowKey={(record) => record.pkgPrefId}
          loading={loading}
          bordered
          scroll={{ x: true }}
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
      <Modal
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1000}
        style={{ top: 20 }}
        footer={null}
      >
        <BaseRow>
          <Descriptions title="Package preferences Info" layout="horizontal" bordered>
            <Descriptions.Item label="Package Pref ID">{viewRecord?.id}</Descriptions.Item>
            <Descriptions.Item label="Name">{viewRecord?.CustomerBean.CustName}</Descriptions.Item>
            <Descriptions.Item label="Total Pax">{viewRecord?.TotalPax}</Descriptions.Item>
            <Descriptions.Item label="Package">{viewRecord?.PackageBean.PackageName}</Descriptions.Item>
            <Descriptions.Item label="Duration">{viewRecord?.Duration.NoOfDays}</Descriptions.Item>
            <Descriptions.Item label="Room Type">{viewRecord?.RoomPrefID}</Descriptions.Item>
            <Descriptions.Item label="Makkah Hotel">{viewRecord?.MakkahHotel?.Distance}</Descriptions.Item>
            <Descriptions.Item label="Madinah Hotel">{viewRecord?.MadinaHotel?.Distance}</Descriptions.Item>
            <Descriptions.Item label="Activated">
              <Badge
                status={viewRecord?.isActive === 1 ? 'success' : 'error'}
                text={viewRecord?.isActive === 1 ? 'ACTIVE' : 'IN-ACTIVE'}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Created Date" time>
              {moment(viewRecord?.createdDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Updated Date" span={2}>
              {moment(viewRecord?.updatedData).format('YYYY-MM-DD')}
            </Descriptions.Item>
          </Descriptions>
        </BaseRow>
      </Modal>
    </div>
  );
};

export default PackagePreferencesList;
