import React, { useEffect, useState } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select, Form } from 'antd';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

const { Option } = Select;

const EditHotel = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [distanceList, setDistanceList] = useState([]);
  const [isCascade, setIsCascade] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setHotelId(id);
    fetchInitialData();
  }, [id]);

 
  const fetchInitialData = async () => {
    setLoading(true);

    try {
      const [hotelData, locations, distances] = await Promise.all([
        getRecordById('/setup/v1/hotels/', id),
        getRecords('/domain/data/locations/v1/all'),
        getRecords('/domain/data/distance/v1/all'),
      ]);

      setLocationList(locations.map((item) => ({ value: item.id, label: item.LocName })));
      setDistanceList(distances.map((item) => ({ value: item.id, label: item.Distance })));

      form.setFieldsValue({
        locationId: hotelData.LocationBean?.id,
        distanceId: hotelData.DistanceBean?.id,
        hotelName: hotelData.HotelName,
        hotelType: hotelData.HotelType,
        address: hotelData.Address,
        remarks: hotelData.Remarks,
        isCascade: hotelData.IsCascade,
      });

      setIsCascade(hotelData.IsCascade);
    } catch (error) {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
    } finally {
      setLoading(false);
    }
  }; 

  const handleSubmit = async (values) => {
    try {
      const updateRequest = { ...values };
      await updateRecord(hotelId, updateRequest, '/setup/v1/hotels/');
      notificationController.success({
        message: 'Hotel updated.',
      });
      navigate('/hotel/list');
    } catch (error) {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
    }
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const handleCancel = () => {
    navigate('/hotel/list');
  };

  return (
    <BaseCol xs={24} sm={24} xl={24}>
      <BaseCard id="validation-form" title="Add Hotel" padding="1.25rem">
        <Form
          form={form}
          onFinish={handleSubmit}
          name="addhotel"
          labelCol={{ span: 6 }}
          style={{ maxWidth: 500 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="Hotel Name"
            name="hotelName"
            rules={[{ required: true, message: 'Hotel name is required!' }]}
            className="ant-latest-form-item"
          >
            <Input placeholder="Enter hotel name" size="small" onKeyDown={(e) => handleEnter(e)} />
          </Form.Item>

          <Form.Item
            label="Hotel Type"
            name="hotelType"
            rules={[{ required: true, message: 'Hotel type is required!' }]}
            className="ant-latest-form-item"
          >
            <Input placeholder="Enter hotel type" size="small" onKeyDown={(e) => handleEnter(e)} />
          </Form.Item>

          <Form.Item
            label="Location"
            name="locationId"
            rules={[{ required: true, message: 'Location is required!' }]}
            className="ant-latest-form-item"
          >
            <Select
              placeholder="Select location"
              size="small"
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              onKeyDown={(e) => handleEnter(e)}
            >
              {locationList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Distance"
            name="distanceId"
            rules={[{ required: true, message: 'Distance is required!' }]}
            className="ant-latest-form-item"
          >
            <Select
              placeholder="Select distance"
              size="small"
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              onKeyDown={(e) => handleEnter(e)}
            >
              {distanceList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Address" name="address" className="ant-latest-form-item">
            <Input placeholder="Enter address" size="small" onKeyDown={(e) => handleEnter(e)} />
          </Form.Item>

          <Form.Item label="Remarks" name="remarks" className="ant-latest-form-item">
            <Input placeholder="Enter remarks" size="small" onKeyDown={(e) => handleEnter(e)} />
          </Form.Item>

          <Form.Item label="Cascade" valuePropName="checked" className="ant-latest-form-item">
            <BaseCheckbox checked={isCascade} onChange={(e) => setIsCascade(e.target.checked)}></BaseCheckbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 9,
            }}
          >
            <BaseButtonsGroup onCancel={handleCancel} loading={loading}/>
          </Form.Item>
        </Form>
      </BaseCard>
    </BaseCol>
  );
};

export default EditHotel;
