import React, { useEffect, useState } from 'react';
import { getRecordById, updateFormRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select, DatePicker, Card, Form, Spin, Tag } from 'antd';
import Moment from 'moment';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { MAX_FILE_SIZE } from '@app/constants';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { Radio } from '@app/components/common/BaseRadio/BaseRadio.styles';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';

const { TextArea } = Input;
const { Option } = Select;

const EditPackage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [priceGroupList, setPriceGroupList] = useState([]);
  const [priceGroup, setPriceGroup] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [previewThumb, setPreviewThumb] = useState(null);
  const [thumbKey, setThumbKey] = useState(Date.now()); // To force re-render the file input
  const [banner, setBanner] = useState(null);
  const [previewBanner, setPreviewBanner] = useState(null);
  const [bannerKey, setBannerKey] = useState(Date.now()); // To force re-render the file input
  const [packageDoc, setPackageDoc] = useState(null);
  const [previewDoc, setPreviewDoc] = useState(null);
  const [docKey, setDocKey] = useState(Date.now()); // To force re-render the file input
  const [isCascade, setIsCascade] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [makkahHotelList, setMakkahHotelList] = useState([]);
  const [madinahHotelList, setMadinahHotelList] = useState([]);
  const [isSpecialBus, setIsSpecialBus] = useState(false);
  const [isMealsIncluded, setIsMealsIncluded] = useState(true);
  const [hideBusCharge, setHideBusCharge] = useState(true);
  const [hideMealCharge, setHideMealCharge] = useState(true);
  const [isBusChargeRequired, setIsBusChargeRequired] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [packageTypeItems] = useState([
    { value: 1, label: 'Shifting' },
    { value: 2, label: 'Non-Shifting' },
    { value: 3, label: 'Not Applicable' },
  ]);
  const [statusItems] = useState([
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [services, durations, pricingGroups, makkahHotels, madinahHotels, packageDetails] = await Promise.all([
          getRecords('/setup/v1/services/all'),
          getRecords('/domain/data/durations/v1/all'),
          getRecords('/domain/data/pricing/group/v1/list'),
          getRecords('/setup/v1/hotels/makkah/list'),
          getRecords('/setup/v1/hotels/medina/list'),
          getRecordById('/setup/v1/packages/', id),
        ]);

        setServiceList(services.map((item) => ({ value: item.id, label: item.ServiceName })));
        setDurationList(durations.map((item) => ({ value: item.id, label: item.NoOfDays })));
        // Sort pricingGroups based on a specific property, for example, 'id'
        const sortedPricingGroups = pricingGroups.sort((a, b) => a.id - b.id); // Adjust sorting criteria as necessary
        setPriceGroupList(sortedPricingGroups);
        setMakkahHotelList(makkahHotels.map((item) => ({ value: item.hotelId, label: item.hotelName + '- (' + item.distance + ')' })));
        setMadinahHotelList(madinahHotels.map((item) => ({ value: item.hotelId, label: item.hotelName + '- (' + item.distance + ')' })));

        // Initialize priceGroup state
        setPriceGroup(
          packageDetails.PriceBean.map((item) => ({
            id: item.CategoryBean.id,
            value: item.PackagePrice || 0.0,
          })),
        );

        form.setFieldsValue({
          serviceId: packageDetails.ServiceBean.id,
          packageName: packageDetails.PackageName,
          packageTypeId: packageDetails.PackageType,
          durationId: packageDetails.DurationBean.id,
          shortDescription: packageDetails.ShortDesc,
          packageDetails: packageDetails.PackageDetails,
          startDate: packageDetails.StartDate === null ? null : Moment(packageDetails.StartDate),
          endDate: packageDetails.EndDate === null ? null : Moment(packageDetails.EndDate),
          priceRange: packageDetails.PriceRange,
          price: packageDetails.Price || 0.0,
          remarks: packageDetails.Remarks,
          isPublished: packageDetails.IsPublished,
          isCascade: packageDetails.IsCascade,
          makkahHotelId: packageDetails.MakkahBean?.id || null,
          madinahHotelId: packageDetails.MadinahBean?.id || null,
          vipTentCharge: packageDetails.VipTent || '',
          busCharge: packageDetails.BusCharge || '',
          mealsCharge: packageDetails.MealsCharge || '',
          recordStat: packageDetails.status,
        });

        setIsCascade(packageDetails.IsCascade);
        setIsPublished(packageDetails.IsPublished);

        //To check isMealsIncluded
        if (packageDetails.IsMealsIncluded === true || packageDetails.IsMealsIncluded === null) {
          setIsMealsIncluded(true);
          setHideMealCharge(true);
          // Set the value in the form
          form.setFieldsValue({ isMealsIncluded: true });
        } else {
          setIsMealsIncluded(false);
          setHideMealCharge(false);
          // Set the value in the form
          form.setFieldsValue({ isMealsIncluded: false });
        }

        //To check isSpecialBus
        if (packageDetails.IsSpecialBus === true) {
          setIsSpecialBus(true);
          setHideBusCharge(false);
        } else {
          setIsSpecialBus(false);
          setHideBusCharge(true);
        }
        setThumbnail(packageDetails.ThumbnailImage);
        setBanner(packageDetails.BannerImage);
        setPackageDoc(packageDetails.DocName);

        setCategoryId(packageDetails.PriceBean.map((item) => ({ id: item.CategoryBean.id })));
      } catch (error) {
        notificationController.error({
          message: `Failed to load data: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('serviceId', values.serviceId);
      formData.append('packageName', values.packageName);
      formData.append('packageTypeId', values.packageTypeId);
      formData.append('durationId', values.durationId);
      formData.append('shortDescription', values.shortDescription);
      formData.append('packageDetails', values.packageDetails);
      formData.append('startDate', values.startDate === null ? '' : Moment(values.startDate).format('YYYY-MM-DD'));
      formData.append('endDate', values.endDate === null ? '' : Moment(values.endDate).format('YYYY-MM-DD'));
      formData.append('isPublished', isPublished);
      formData.append('isCascade', isCascade);
      formData.append('recordStat', values.recordStat);
      formData.append('priceRange', values.priceRange == null ? '' : values.priceRange);
      formData.append('price', values.price !== 0.0 ? values.price : 0.0);
      formData.append('remarks', values.remarks == null ? '' : values.remarks);
      formData.append('thumbnail', thumbnail);
      formData.append('banner', banner);
      formData.append('packageDoc', packageDoc);
      formData.append('makkahHotelId', values.makkahHotelId == null ? '' : values.makkahHotelId);
      formData.append('madinahHotelId', values.madinahHotelId == null ? '' : values.madinahHotelId);
      formData.append('vipTentCharge', values.vipTentCharge !== 0.0 ? values.vipTentCharge : 0.0);
      formData.append('isSpecialBus', isSpecialBus);

      // Convert `priceGroup` to a comma-separated string of values
      const priceGroupValues = priceGroup.map((item) => item.value).join(',');

      // Convert `categoryId` to a comma-separated string of ids
      const categoryIdValues = categoryId.map((item) => item.id).join(',');
      // Append to FormData
      formData.append('priceGroup', priceGroupValues);
      formData.append('categoryId', categoryIdValues);

      if (values.isSpecialBus === false) {
        formData.append('busCharge', values.busCharge);
      } else {
        formData.append('busCharge', values.busCharge != null && values.busCharge !== 0 ? values.busCharge : 0);
      }

      formData.append('isMealsIncluded', values.isMealsIncluded);

      // Handle mealsCharge similarly: default to 0 if mealsCharge is not provided or is 0
      if (values.isMealsIncluded === true) {
        formData.append('mealsCharge', values.mealsCharge);
      } else {
        formData.append('mealsCharge', values.mealsCharge != null && values.mealsCharge !== 0 ? values.mealsCharge : 0);
      }

      await updateFormRecord(id, formData, '/setup/v1/packages/');

      notificationController.success({
        message: 'Package updated!',
      });

      navigate('/package/list');
    } catch (error) {
      notificationController.error({
        message: `Failed to update package: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  /* const onChangedPriceGroup = (index, id) => (e) => {
    const updatedPriceGroup = [...priceGroup];
    const updatedCategoryId = [...categoryId];
    updatedPriceGroup[index] = e.target.value;
    updatedCategoryId[index] = id;
    setPriceGroup(updatedPriceGroup);
    setCategoryId(updatedCategoryId);
  }; */

  const handleDateChange = (date, dateString) => {
    console.log('Selected Date:', date, 'Formatted Date:', dateString);
  };

  const handleThumbChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // Reset the input field by changing its key (force re-render)
      setThumbKey(Date.now());
      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
      return;
    }
    const fileUrl = URL.createObjectURL(uploadedFile);
    setPreviewThumb(fileUrl);
    setThumbnail(uploadedFile);
  };

  const handleBannerChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // Reset the input field by changing its key (force re-render)
      setBannerKey(Date.now());
      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
      return;
    }
    const fileUrl = URL.createObjectURL(uploadedFile);
    setPreviewBanner(fileUrl);
    setBanner(uploadedFile);
  };

  const handleDocChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // Reset the input field by changing its key (force re-render)
      setDocKey(Date.now());
      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
      return;
    }
    setPreviewDoc(uploadedFile.name);
    setPackageDoc(uploadedFile);
  };

  const handleChangeIsCascade = (e) => {
    setIsCascade(e.target.checked);
  };

  const handleChangeIsPublished = (e) => {
    setIsPublished(e.target.checked);
  };

  const onChangeSpecialBus = (e) => {
    console.log('radio checked', e.target.value);
    setIsSpecialBus(e.target.value);

    if (e.target.value === true) {
      setHideBusCharge(false);
      setIsBusChargeRequired(true);
    } else {
      setHideBusCharge(true);
      setIsBusChargeRequired(false);
    }
  };

  const handleChangeIsMealsInclude = (e) => {
    const { checked } = e.target;
    setIsMealsIncluded(checked);
    form.setFieldsValue({ isMealsIncluded: checked }); // Update form value when checkbox changes
    setHideMealCharge(checked);

    if (checked) {
      setIsRequired(false);
    } else {
      setIsRequired(true);
    }
    form.setFieldsValue({
      isMealsIncluded: e.target.checked,
    });
    form.validateFields(['isMealsIncluded']); // Trigger validation after change
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const onCancelForm = (event) => {
    event.preventDefault();
    navigate('/package/list');
  };

  return (
    <Spin spinning={isLoading}>
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Edit Package" padding="1.25rem">
          <Form
            form={form}
            onFinish={handleSubmit}
            className="service-form"
            name="basic"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 20 }}
            style={{ maxWidth: 1200 }}
            autoComplete="off"
          >
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={24} sm={20} xl={12}>
                <Form.Item
                  label="Service"
                  name="serviceId"
                  rules={[{ required: true, message: 'Service is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={handleEnter}
                  >
                    {serviceList.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Package Name"
                  name="packageName"
                  rules={[
                    { required: true, message: 'Package name is required!' },
                    { min: 4, message: 'Package name should be minimum 4 characters' },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input size="small" placeholder="Enter package name" onKeyDown={handleEnter} />
                </Form.Item>

                <Form.Item
                  label="Package Type"
                  name="packageTypeId"
                  rules={[{ required: true, message: 'Package type is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={handleEnter}
                  >
                    {packageTypeItems.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Package Duration"
                  name="durationId"
                  rules={[{ required: true, message: 'Duration is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select duration"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                    optionFilterProp="children"
                    onSearch={onSearch}
                  >
                    {durationList.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Short Description"
                  name="shortDescription"
                  rules={[{ required: true, message: 'Short description is required!' }]}
                  className="ant-latest-form-item"
                >
                  <TextArea
                    rows={2}
                    placeholder="Enter short description"
                    onKeyDownCapture={(e) => {
                      if (e.keyCode === 78 && e.altKey) {
                        e.preventDefault();
                        e.target.value += '\r\n';
                      }
                    }}
                    onKeyDown={handleEnter}
                  />
                </Form.Item>

                <Form.Item
                  label="Package Details"
                  name="packageDetails"
                  rules={[{ required: true, message: 'Package details is required!' }]}
                  className="ant-latest-form-item"
                >
                  <TextArea
                    rows={2}
                    placeholder="Enter package details"
                    onKeyDownCapture={(e) => {
                      if (e.keyCode === 78 && e.altKey) {
                        e.preventDefault();
                        e.target.value += '\r\n';
                      }
                    }}
                    onKeyDown={handleEnter}
                  />
                </Form.Item>

                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: false, message: 'Start date is required!' }]}
                  className="ant-latest-form-item"
                >
                  <DatePicker
                    size="small"
                    format={startDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                    placeholder="Select Start Date"
                    onOpenChange={(status) => {
                      setStartDateOpen(status);
                    }}
                    onChange={handleDateChange}
                    onKeyDown={handleEnter}
                  />
                </Form.Item>

                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: false, message: 'End date is required!' }]}
                  className="ant-latest-form-item"
                >
                  <DatePicker
                    size="small"
                    format={endDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                    placeholder="Select End Date"
                    onOpenChange={(status) => {
                      setEndDateOpen(status);
                    }}
                    onChange={handleDateChange}
                    onKeyDown={handleEnter}
                  />
                </Form.Item>

                <Form.Item
                  label="Thumbnail"
                  name="thumbnail"
                  rules={[{ required: false, message: 'Please choose a thumbnail!' }]}
                  className="ant-latest-form-item"
                >
                  <Input
                    type="file"
                    accept="image/*"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                    onChange={handleThumbChange}
                    key={thumbKey} // Key is updated to force re-render
                  />

                  {previewThumb && (
                    <div style={{ marginTop: 10 }}>
                      <img src={previewThumb} alt="Thumbnail Preview" style={{ height: 120, width: 120 }} />
                    </div>
                  )}
                </Form.Item>

                <Form.Item
                  label="Banner"
                  name="banner"
                  rules={[{ required: false, message: 'Please choose a banner!' }]}
                  className="ant-latest-form-item"
                >
                  <Input
                    type="file"
                    accept="image/*"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                    onChange={handleBannerChange}
                    key={bannerKey} // Key is updated to force re-render
                  />

                  {previewBanner && (
                    <div style={{ marginTop: 10 }}>
                      <img src={previewBanner} alt="Banner Preview" style={{ height: 120, width: 120 }} />
                    </div>
                  )}
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Remarks is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Input size="small" placeholder="Enter remarks" onKeyDown={(e) => handleEnter(e)} />
                </Form.Item>

                <Form.Item
                  label="Document"
                  name="packageDoc"
                  rules={[{ required: false, message: 'Please Choose document!' }]}
                  className="ant-latest-form-item"
                >
                  <Input
                    type="file"
                    accept=".doc, .docx, .xlsx, .xls, .pdf"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                    onChange={handleDocChange}
                    key={docKey} // Key is updated to force re-render
                  />

                  {previewDoc && (
                    <div style={{ marginTop: 10 }}>
                      <Tag color="success">{previewDoc != null ? previewDoc : packageDoc}</Tag>
                    </div>
                  )}
                </Form.Item>

                <Form.Item label="Cascade" name="isCascade" className="ant-latest-form-item">
                  <BaseCheckbox onChange={handleChangeIsCascade} checked={isCascade} onKeyDown={handleEnter} />
                </Form.Item>

                <Form.Item label="Published" name="isPublished" className="ant-latest-form-item">
                  <BaseCheckbox onChange={handleChangeIsPublished} checked={isPublished} onKeyDown={handleEnter} />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="recordStat"
                  rules={[{ required: true, message: 'Please select status!' }]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={handleEnter}
                  >
                    {statusItems.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </BaseCol>
              <BaseCol xs={24} sm={20} xl={12}>
                <Form.Item
                  label="Price Range"
                  name="priceRange"
                  rules={[{ required: false, message: 'Price range is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Input size="small" placeholder="Enter price range" onKeyDown={(e) => handleEnter(e)} />
                </Form.Item>

                <Form.Item
                  label="Base Price"
                  name="price"
                  rules={[
                    { required: false, message: 'Base price is required!' },
                    { pattern: /^(?:\d*)$/, message: 'Price should contain only numbers' },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input
                    addonBefore="BDT"
                    size="small"
                    placeholder="Enter base price"
                    onKeyDown={(e) => handleEnter(e)}
                  />
                </Form.Item>

                <Form.Item label="Pricing Group">
                  <Card bordered style={{ width: 370, marginTop: 10, borderColor: 'red' }}>
                    {priceGroupList.map((row) => (
                      <Form.Item
                        label={row.CategoryName}
                        key={row.id}
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 20 }}
                        className="ant-latest-form-item"
                      >
                        <Input
                          required
                          name="priceGroup"
                          size="small"
                          placeholder="Enter price"
                          value={priceGroup.find((item) => item.id === row.id)?.value}
                          onChange={(e) => {
                            const updatedPriceGroup = [...priceGroup];
                            const updatedCategoryId = [...categoryId];
                            // Find the index of the current row in priceGroup
                            const priceGroupIndex = updatedPriceGroup.findIndex((item) => item.id === row.id);
                            // Update priceGroup
                            if (priceGroupIndex !== -1) {
                              updatedPriceGroup[priceGroupIndex].value = e.target.value;
                            } else {
                              updatedPriceGroup.push({ id: row.id, value: e.target.value });
                            }
                            // Ensure the categoryId is included
                            if (!updatedCategoryId.some((item) => item.id === row.id)) {
                              updatedCategoryId.push({ id: row.id });
                            }
                            // Update states
                            setPriceGroup(updatedPriceGroup);
                            setCategoryId(updatedCategoryId);
                          }}
                          onKeyDown={(e) => handleEnter(e)}
                        />
                      </Form.Item>
                    ))}
                  </Card>
                </Form.Item>

                <Form.Item
                  label="Makkah Hotel"
                  name="makkahHotelId"
                  rules={[{ required: false, message: 'Makkah hotel is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select makkah hotel"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={handleEnter}
                  >
                    {makkahHotelList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Madinah Hotel"
                  name="madinahHotelId"
                  rules={[{ required: false, message: 'Madinah hotel is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select madinah hotel"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={handleEnter}
                    selectDefaultFirstOption={true}
                  >
                    {madinahHotelList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="VIP Tent (Add’l. Chrg.)"
                  name="vipTentCharge"
                  rules={[
                    { required: false, message: 'Additional charge is required!' },
                    { pattern: /^(?:\d*)$/, message: 'Charge should contain only numbers' },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input
                    addonBefore="BDT"
                    size="small"
                    placeholder="Enter vip additional charge"
                    onKeyDown={handleEnter}
                  />
                </Form.Item>

                <Form.Item
                  label="Special Bus (Incl.)"
                  name="isSpecialBus"
                  initialValue={isSpecialBus}
                  rules={[{ required: true, message: 'Special bus is required!' }]}
                  className="ant-latest-form-item"
                >
                  <Radio.Group onChange={onChangeSpecialBus} onKeyDown={handleEnter}>
                    <Radio value={false}>NO</Radio>
                    <Radio value={true}>YES</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Additional Charge"
                  name="busCharge"
                  hidden={hideBusCharge}
                  rules={[
                    {
                      required: isBusChargeRequired,
                      message: 'Bus additional charge required!',
                    },
                    { pattern: /^(?:\d*)$/, message: 'Charge should contain only numbers' },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input
                    addonBefore="BDT"
                    size="small"
                    placeholder="Enter bus additional charge"
                    onKeyDown={handleEnter}
                  />
                </Form.Item>

                <Form.Item
                  label="Meals (Included)"
                  name="isMealsIncluded"
                  rules={[{ required: true, message: 'Meals (Included) is required!' }]}
                  className="ant-latest-form-item"
                >
                  <BaseCheckbox
                    onChange={handleChangeIsMealsInclude}
                    checked={isMealsIncluded}
                    onKeyDown={handleEnter}
                  />
                </Form.Item>
                <Form.Item
                  label="Without Meals"
                  name="mealsCharge"
                  hidden={hideMealCharge}
                  rules={[
                    {
                      required: isRequired,
                      message: 'Meals deducted charge required!',
                    },
                    { pattern: /^(?:\d*)$/, message: 'Charge should contain only numbers' },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input
                    addonBefore="BDT"
                    size="small"
                    placeholder="Enter deducted meals charge"
                    onKeyDown={handleEnter}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 7,
                    span: 9,
                  }}
                >
                  <BaseButtonsGroup onCancel={onCancelForm} loading={isLoading} />
                </Form.Item>
              </BaseCol>
            </BaseRow>
          </Form>
        </BaseCard>
      </BaseCol>
    </Spin>
  );
};

export default EditPackage;
