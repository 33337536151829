import React, { useState, useEffect } from 'react';
import { saveRecord, getRecords, searchRecord } from '@app/utils/APIUtils';
import { Descriptions, Input, Select, Form } from 'antd';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import SearchCustomer from '@app/components/search-customer/SearchCustomer';

const { Option } = Select;

const AddPackagePreferences = () => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [durationList, setDurationList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [makkahHotelList, setMakkahHotelList] = useState([]);
  const [madinaHotelList, setMadinaHotelList] = useState([]);
  const [recordSet, setRecordSet] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [roomPreferenceList, setRoomPreferenceList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLists = async () => {
      setLoading(true);
      try {
        const durations = await getRecords('/domain/data/durations/v1/all');
        //const packages = await getRecords('/setup/v1/packages/list/all');
        const rooms = await getRecords('/domain/data/pricing/group/v1/list');
        const makkahHotels = await getRecords('/domain/data/distance/v1/all');
        const madinaHotels = await getRecords('/domain/data/distance/v1/all');

        setDurationList(durations.map((item) => ({ value: item.id, label: item.NoOfDays })));
        /* setPackageList(
          packages.map((item) => ({
            value: item.id,
            label: item.PackageName + ' - ' + item.DurationBean.NoOfDays,
          })),
        ); */
        setRoomPreferenceList(
          rooms.map((item) => ({ value: item.id, label: item.CategoryName })).sort((a, b) => a.value - b.value),
        );
        setMakkahHotelList(makkahHotels.map((item) => ({ value: item.id, label: item.Distance })));
        setMadinaHotelList(madinaHotels.map((item) => ({ value: item.id, label: item.Distance })));
      } catch (error) {
        notificationController.error({ message: 'Error fetching data', description: error.message });
      } finally {
        setLoading(false);
      }
    };
    fetchLists();
  }, []);

  const handleSearch = (values) => {
    const { mobileNumber, groupNumber, customerName } = values;
    if (mobileNumber === undefined && groupNumber === undefined && customerName === undefined) {
      notificationController.warning({
        message: 'Please enter one filtering criteria!',
      });
    }
    const searchRequest = {
      customerName: customerName?.trim() || null,
      mobileNumber: mobileNumber?.trim() || null,
      groupNumber: groupNumber?.trim() || null,
    };

    searchRecord(searchRequest, '/public/v1/customers/search')
      .then((response) => {
        setRecordSet(response);
      })
      .catch((error) => {
        this.setState({ recordSet: [] });
        notificationController.error({
          message: 'Search failed!',
          description: error.message || 'An unexpected error occurred.',
        });
      });
  };

  const handleRowClick = (record) => {
    setCustomerInfo(record); // Assuming `setCustomerInfo` is a state setter for `customerInfo`

    form.setFieldsValue({
      customerId: record.customerId, // Assuming `form` is an instance of Ant Design's useForm
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await saveRecord(values, '/customer/package/v1/preferences');
      notificationController.success({ message: 'Package preferences created.' });
      navigate('/package_preferences/list');
    } catch (error) {
      notificationController.error({ message: 'Failed to save preferences', description: error.message });
    }
  };

  const handleCancel = () => {
    navigate('/package_preferences/list');
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const handleDurationChange = (durationId) => {
    form.resetFields(['packageId']);
    getRecords(`/setup/v1/packages/list/${durationId}/duration`).then((response) =>
      setPackageList(
        response.map((item) => ({
          value: item.packageId,
          label: item.packageName,
        })),
      ),
    ).catch(() => setPackageList([]));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'customerName',
      key: 'customerName;',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Group Number',
      dataIndex: 'groupNumber',
      key: 'groupNumber',
    },
  ];

  return (
    <BaseCard id="validation form" title="Add Package Preferences" padding="1.25rem" loading={isLoading}>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={20} xl={12}>
          <SearchCustomer
            searchForm={form}
            onFinish={handleSearch}
            customerName="customerName"
            mobileNumber="mobileNumber"
            groupNumber="groupNumber"
            onKeyDown={(e) => handleEnter(e)}
          />
        </BaseCol>
        <BaseCol xs={24} sm={20} xl={12}>
          <BaseCard id="search" padding="1.25rem">
            <BaseRow gutter={[0, 0]}>
              <BaseCol xs={24} sm={24} xl={24}>
                <BaseTable
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record), // Handle row click
                  })}
                  columns={columns}
                  dataSource={recordSet}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: true }}
                  rowKey={(record) => record.customerId}
                  bordered
                />
              </BaseCol>
            </BaseRow>
          </BaseCard>
        </BaseCol>
      </BaseRow>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={12} xl={12}>
          <BaseCard id="search" padding="1.25rem">
            <Descriptions title="Customer Info" bordered size="small">
              <Descriptions.Item label="Name" span={3}>
                {customerInfo.customerName}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {customerInfo.email}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                {customerInfo.mobileNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {customerInfo.address}
              </Descriptions.Item>
              <Descriptions.Item label="Reference" span={3}>
                {customerInfo.reference}
              </Descriptions.Item>
            </Descriptions>
          </BaseCard>
        </BaseCol>
        <BaseCol xs={24} sm={20} xl={12}>
          <Form
            form={form}
            onFinish={handleSubmit}
            className="preferences-form"
            name="basic"
            labelCol={{ span: 7 }}
            style={{ maxWidth: 500, marginTop: '20px' }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              label="Customer Id"
              name="customerId"
              rules={[
                {
                  required: true,
                  message: 'Customer id is required!',
                },
              ]}
              className="ant-latest-form-item"
              hidden={true}
            >
              {/* <-- Pass hidden customerId --> */}
              <Input
                size="small"
                readOnly={true}
                onKeyDown={(e) => handleEnter(e)}
                style={{ backgroundColor: '#c5d3e0' }}
              />
            </Form.Item>
            <Form.Item
              name="totalPersons"
              label="Total Pax"
              rules={[
                { required: false, message: 'Total pax is required!' },
                { pattern: /^(?:\d*)$/, message: 'Pax should contain only numbers' },
              ]}
              className="ant-latest-form-item"
            >
              <Input placeholder="Enter total pax" size="small" onKeyDown={(e) => handleEnter(e)} />
            </Form.Item>

            <Form.Item
              name="pkgDurationId"
              label="Package Duration"
              rules={[{ required: false, message: 'Package duration is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select duration"
                size="small"
                optionFilterProp="children"
                onChange={handleDurationChange}
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {durationList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="packageId"
              label="Package Name"
              rules={[{ required: false, message: 'Package name is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Package"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {packageList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="roomPreference"
              label="Room Type"
              rules={[{ required: false, message: 'Room type is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Room Type"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {roomPreferenceList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="hotelMakkahId"
              label="Hotel in Makkah"
              rules={[{ required: false, message: 'Hotel in Makkah is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Hotel"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {makkahHotelList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="hotelMedinaId"
              label="Hotel in Madinah"
              rules={[{ required: false, message: 'Hotel in Madinah is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Hotel"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {madinaHotelList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="remarks" label="Remarks" className="ant-latest-form-item">
              <Input placeholder="Enter remarks" size="small" onKeyDown={(e) => handleEnter(e)} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 7,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={handleCancel} />
            </Form.Item>
          </Form>
        </BaseCol>
      </BaseRow>
    </BaseCard>
  );
};

export default AddPackagePreferences;
