import React, { useEffect, useState } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Descriptions, Input, Select, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { notificationController } from '@app/controllers/notificationController';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const { Option } = Select;

const EditPackagePreferences = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id: pkgPrefId } = useParams();
  const [packageList, setPackageList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [makkahHotelList, setMakkahHotelList] = useState([]);
  const [madinaHotelList, setMadinaHotelList] = useState([]);
  const [roomPreferenceList, setRoomPreferenceList] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [durations, /* packages, */ rooms, makkahHotels, madinaHotels, preferences] = await Promise.all([
          getRecords('/domain/data/durations/v1/all'),
          //getRecords('/setup/v1/packages/list/all'),
          getRecords('/domain/data/pricing/group/v1/list'),
          getRecords('/domain/data/distance/v1/all'),
          getRecords('/domain/data/distance/v1/all'),
          getRecordById('/customer/package/v1/preferences/', pkgPrefId),
        ]);

        setDurationList(durations.map((item) => ({ value: item.id, label: item.NoOfDays })));
       /*  setPackageList(packages.map((item) => ({ 
          value: item.id, 
          label: item.PackageName + ' - ' + item.DurationBean.NoOfDays 
        }))); */
        setRoomPreferenceList(rooms.map((item) => ({ value: item.id, label: item.CategoryName }))
          .sort((a, b) => a.value  - b.value)
        );  
        setMakkahHotelList(makkahHotels.map((item) => ({ value: item.id, label: item.Distance })));
        setMadinaHotelList(madinaHotels.map((item) => ({ value: item.id, label: item.Distance })));
        setCustomerInfo(preferences.CustomerBean);

        form.setFieldsValue({
          totalPersons: preferences.TotalPax,
          pkgDurationId: preferences.Duration?.id,
          packageId: preferences.PackageBean?.id,
          hotelMakkahId: preferences.MakkahHotel?.id,
          hotelMedinaId: preferences.MadinaHotel?.id,
          roomPreference: preferences.RoomPrefID,
          remarks: preferences.Remarks,
        });

        getRecords(`/setup/v1/packages/list/${preferences.Duration?.id}/duration`).then((response) =>
          setPackageList(
            response.map((item) => ({
              value: item.packageId,
              label: item.packageName,
            })),
          ),
        ).catch(() => setPackageList([]));

      } catch (error) {
        notificationController.error({
          message: error.message,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pkgPrefId, form]);

  const handleSubmit = async (values) => {
    try {
      await updateRecord(pkgPrefId, values, '/customer/package/v1/preferences/');
      notificationController.success({ message: 'Package preferences updated.' });
      navigate('/package_preferences/list');
    } catch (error) {
      notificationController.error({ message: 'Failed!' + error.message });
    }
  };

  const handleCancel = () => {
    navigate('/package_preferences/list');
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const handleDurationChange = (durationId) => {
    form.resetFields(['packageId']);
    getRecords(`/setup/v1/packages/list/${durationId}/duration`).then((response) =>
      setPackageList(
        response.map((item) => ({
          value: item.packageId,
          label: item.packageName,
        })),
      ),
    ).catch(() => setPackageList([]));
  };

  return (
    <BaseCard title="Edit Package Preferences" loading={isLoading}>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={20} xl={12}>
          <Form
            form={form}
            onFinish={handleSubmit}
            className="preferences-form"
            name="basic"
            labelCol={{ span: 7 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              name="totalPersons"
              label="Total Pax"
              rules={[
                { required: false, message: 'Total pax is required!' },
                { pattern: /^(?:\d*)$/, message: 'Pax should contain only numbers' },
              ]}
              className="ant-latest-form-item"
            >
              <Input placeholder="Enter total pax" size="small" onKeyDown={(e) => handleEnter(e)} />
            </Form.Item>

            <Form.Item
              name="pkgDurationId"
              label="Package Duration"
              rules={[{ required: false, message: 'Package duration is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select duration"
                size="small"
                optionFilterProp="children"
                onChange={handleDurationChange}
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {durationList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="packageId"
              label="Package Name"
              rules={[{ required: false, message: 'Package name is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Package"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {packageList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="roomPreference"
              label="Room Type"
              rules={[{ required: false, message: 'Room type is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Room Type"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {roomPreferenceList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="hotelMakkahId"
              label="Hotel in Makkah"
              rules={[{ required: false, message: 'Hotel in Makkah is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Hotel"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {makkahHotelList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="hotelMedinaId"
              label="Hotel in Madinah"
              rules={[{ required: false, message: 'Hotel in Madinah is required!' }]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select Hotel"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={handleEnter}
              >
                {madinaHotelList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="remarks" label="Remarks" className="ant-latest-form-item">
              <Input placeholder="Enter remarks" size="small" onKeyDown={(e) => handleEnter(e)} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 7,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={handleCancel} />
            </Form.Item>
          </Form>
        </BaseCol>

        <BaseCol xs={24} sm={12} xl={12}>
          <Descriptions title="Customer Info" bordered size="middle">
            <Descriptions.Item label="Name" span={3}>
              {customerInfo.CustName}
            </Descriptions.Item>
            <Descriptions.Item label="Profession" span={3}>
              {customerInfo.ProfName}
            </Descriptions.Item>
            <Descriptions.Item label="Email" span={3}>
              {customerInfo.Email}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile" span={3}>
              {customerInfo.MobileNo}
            </Descriptions.Item>
            <Descriptions.Item label="WhatsApp" span={3}>
              {customerInfo.WhatsApp}
            </Descriptions.Item>
            <Descriptions.Item label="Address" span={3}>
              {customerInfo.AddressLine1}
            </Descriptions.Item>
            <Descriptions.Item label="Reference" span={3}>
              {customerInfo.Reference}
            </Descriptions.Item>
          </Descriptions>
        </BaseCol>
      </BaseRow>
    </BaseCard>
  );
};

export default EditPackagePreferences;
