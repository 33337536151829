import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { ACCESS_TOKEN } from 'constants/index';
import { notificationController } from '@app/controllers/notificationController';
import { deleteItemByParam, getCurrentUser } from '@app/utils/APIUtils';

const ProfileOverlay = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState('');

  const loadCurrentUser = async () => {
    try {
      const response = await getCurrentUser();
      setUsername(response.username);
    } catch (error) {
      notificationController.error({
        message: 'Failed! User not found',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async (redirectTo = '/auth/login', notificationType = 'success', message = "You're logged out.") => {
    try {
      const res = await deleteItemByParam(`/auth/logout?username=${username}`);
      if (res.statusCode === 200) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem("username");
        navigate(redirectTo);
        notificationController[notificationType]({
          message: message,
        });
      }
    } catch (error) {
      notificationController.error({
        message: error.message,
      });
    }
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      handleLogout();
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  if (isLoading) {
    return null; // Optionally, you can add a spinner or placeholder while loading
  }

  return (
    <div>
      <Menu
        selectedKeys={[]}
        items={[
          {
            key: 'profile',
            label: <Link to={`/profile/personal-info/${username}`}>Profile</Link>,
          },
          {
            key: 'logout',
            label: 'Logout',
            onClick: handleMenuClick,
          },
        ]}
      />
    </div>
  );
};

export default ProfileOverlay;
