import React, { useState, useEffect, useCallback } from 'react';
import { getRecordById, getRecords, getRecordSet } from '@app/utils/APIUtils';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { ImportOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { LIST_SIZE } from '@app/constants';
import { Badge, Button, Descriptions, Modal, Row, Tag } from 'antd';
import debounce from 'lodash.debounce';
import PDFDownloader from '@app/components/pdf-downloader/PDFDownloader';

const RegistrationList = () => {
  const [loading, setLoading] = useState(false);
  const [recordSet, setRecordSet] = useState([]);
  const [pdfRecordSet, setPdfRecordSet] = useState([]);
  const [agencyName, setAgencyName] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const [visible, setVisible] = useState(false);
  const [viewRecord, setViewRecord] = useState({});
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(LIST_SIZE);
  const [totalElements, setTotalElements] = useState(0);
  const navigate = useNavigate();

  const loadRecords = useCallback(
    debounce((searchedText, page, size = LIST_SIZE) => {
      setLoading(true);
      getRecordSet(page, size, '/public/customer/v1/registered?page=', searchedText)
        .then((response) => {
          setRecordSet(response.content);
          setPage(response.page);
          setSize(response.size);
          setTotalElements(response.totalElements);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  //Load export as PDF records
  const loadPDFRecords = () => {
    getRecords('/public/customer/v1/registered/all').then((response) => {
      setPdfRecordSet(response);
    })
      .catch((error) => {
        notificationController.error({ message: 'Failed! ' + error.message });
      });

    const username = localStorage.getItem('username'); // Get username from local storage
    // Get user details by username
    getRecords(`/sa/users/${username}`).then((response) => {
      setAgencyName(response.agentName);
    })
      .catch((error) => {
        notificationController.error({ message: 'Failed! ' + error.message });
      });
  };

  useEffect(() => {
    loadRecords(searchedText, page, size);
    loadPDFRecords();
  }, [searchedText, page, size, loadRecords]);

  const save = () => {
    navigate('/registration/new');
  };

  const edit = (id) => {
    navigate(`/registration/edit/${id}`);
  };

  const view = (id) => {
    setVisible(true);
    getRecordById('/public/customer/v1/registered/', id).then((res) => {
      setViewRecord(res);
    });
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const importCsvFile = () => {
    navigate('/registration/import/csv');
  }

  const data = recordSet.map((record, index) => ({
    key: index,
    registrationId: record.regId,
    hajYearId: record.hajYearId,
    hajSerialNo: record.hajSerialNo,
    customerName: record.custName,
    mobileNumber: record.mobileNo,
    groupNumber: record.groupNumber || '',
    passportNo: record.passportNo,
    registrationDate: moment(record.regDate).format('YYYY-MM-DD'),
    regVoucherNo: record.regVoucherNo,
    pilgrimId: record.pid,
  }));

  const columns = [
    {
      title: 'Group No',
      dataIndex: 'groupNumber',
      key: 'groupNumber',
      render: (_, { groupNumber }) => {
        let color = groupNumber ? '#108ee9' : '';
        return groupNumber ? (
          <Tag color={color} key={groupNumber}>
            {groupNumber}
          </Tag>
        ) : null;
      },
    },
    { title: 'Haj Year', dataIndex: 'hajYearId', key: 'hajYearId' },
    { title: 'Haj Serial No', dataIndex: 'hajSerialNo', key: 'hajSerialNo' },
    { title: 'Customer Name', dataIndex: 'customerName', key: 'customerName' },
    { title: 'Mobile', dataIndex: 'mobileNumber', key: 'mobileNumber' },
    { title: 'Reg. Date', dataIndex: 'registrationDate', key: 'registrationDate' },
    { title: 'Reg Voucher No', dataIndex: 'regVoucherNo', key: 'regVoucherNo' },
    { title: 'Pilgrim ID', dataIndex: 'pilgrimId', key: 'pilgrimId' },
    {
      title: 'Action',
      key: 'action',
      width: '12%',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton size="small" type="primary" onClick={() => view(record.registrationId)}>
            View
          </BaseButton>
          <BaseButton severity="info" size="small" onClick={() => edit(record.registrationId)}>
            Edit
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  //Download records as PDF section
  // Define title and sub-title
  const hajYearIds = [...new Set(pdfRecordSet.map((data) => data.hajYearId))]; //Get hajYear from pdfRecordSet
  const leftTitle = agencyName || 'Agency Name'; // Fallback if MyUnit is empty
  const subTitle = `List of Registered Pilgrims For Hajj ${hajYearIds}`;
  const rightTitle = 'Printed on:';
  //Pdf report column
  const reportColumns = [
    'SL',
    'Group No',
    'Customer Name',
    'Mobile',
    'Reg. Date',
    'Haj Serial No',
    'Pilgrim ID',
  ];

  //Map pdfRecords
  const pdfRecords = pdfRecordSet.map((data, index) => [
    index + 1,
    data.groupNumber,
    data.custName,
    data.mobileNo,
    data.regDate,
    data.hajSerialNo,
    data.pid,
  ]);

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title="Registration List"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton type="info" className="btn btn-dark" onClick={save} size="small" icon={<PlusCircleOutlined />}>
            Add
          </BaseButton>
        }
      >
        <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onChange={(e) => {
                setSearchedText(e.target.value);
                loadRecords(e.target.value);
              }}
              style={{ width: 230, marginBottom: 10 }}
              allowClear
              size="small"
            />
          </ST.InputsWrapper>
          <div className="importBtn">
            <BaseSpace>
              <Button
                type="primary"
                className="btn btnImport"
                onClick={importCsvFile}
                size="small"
                icon={<ImportOutlined />}
              >
                Import
              </Button>
              <PDFDownloader
                leftTitle={leftTitle}
                rightTitle={rightTitle}
                subTitle={subTitle}
                pdfRecordSet={pdfRecords}
                reportColumns={reportColumns}
                setTableLoading={setLoading} // Pass loading state
              />
            </BaseSpace>
          </div>
        </div>
        <BaseTable
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: size,
            total: totalElements,
            showTotal: (total) => (
              <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {total} records</span>
            ),
            onChange: (newPage) => setPage(newPage - 1),
            /* pageSizeOptions: ['10', '20', '30', '50'], // Options for page size
            showSizeChanger: true, // Show the size changer
            onShowSizeChange: (current, size) => setSize(size), // Handle page size change */
          }}
          rowKey={(record) => record.regId}
          loading={loading}
          bordered
          scroll={{ x: true }}
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
      <Modal
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1000}
        style={{ top: 20 }}
        footer={null}
      >
        <Row>
          <Descriptions title="Registration Info" layout="horizontal" bordered size="middle">
            <Descriptions.Item label="REGID">{viewRecord.regId}</Descriptions.Item>
            <Descriptions.Item label="Customer Name">{viewRecord.customerName}</Descriptions.Item>
            <Descriptions.Item label="Mobile Number">{viewRecord.mobileNumber}</Descriptions.Item>
            <Descriptions.Item label="Email">{viewRecord.email}</Descriptions.Item>
            <Descriptions.Item label="Haj Year">{viewRecord.hajYear}</Descriptions.Item>
            <Descriptions.Item label="Haj Serial">{viewRecord.hajSerial}</Descriptions.Item>
            <Descriptions.Item label="Reg Date">
              {moment(viewRecord.registrationDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Reg Boucher No">{viewRecord.regVoucherNo}</Descriptions.Item>
            <Descriptions.Item label="Pilgrim ID">{viewRecord.pilgrimId}</Descriptions.Item>
            <Descriptions.Item label="Biometrics ID">{viewRecord.biometricsId}</Descriptions.Item>
            <Descriptions.Item label="E-Haj Tag">{viewRecord.ehajTag}</Descriptions.Item>
            <Descriptions.Item label="Visa No">{viewRecord.visaNo}</Descriptions.Item>
            <Descriptions.Item label="Status">{viewRecord.recordStat === 3 ? 'Registered' : ''}</Descriptions.Item>
            <Descriptions.Item label="Created Date">
              {moment(viewRecord.createdAt).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Updated Date">
              {moment(viewRecord.updatedAt).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Created By">{viewRecord.createdBy}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {moment(viewRecord.createdDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Updated By">{viewRecord.updatedBy}</Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {moment(viewRecord.updatedDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Activated">
              <Badge
                status={viewRecord.isActive === 1 ? 'success' : 'error'}
                text={viewRecord.isActive === 1 ? 'Yes' : 'No'}
              />
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Modal>
    </div>
  );
};

export default RegistrationList;
