import React, { useState, useEffect, useCallback } from 'react';
import { getRecords, getRecordSet } from '@app/utils/APIUtils';
import { Tag } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import PDFDownloader from '@app/components/pdf-downloader/PDFDownloader';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import debounce from 'lodash.debounce';

const PaymentList = () => {
  const [recordSet, setRecordSet] = useState([]);
  const [pdfRecordSet, setPdfRecordSet] = useState([]);
  const [agencyName, setAgencyName] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(LIST_SIZE);
  const [totalElements, setTotalElements] = useState(0);

  const navigate = useNavigate();

  const loadRecords = useCallback(
    debounce((searchedText, page, size = LIST_SIZE) => {
      setLoading(true);
      getRecordSet(page, size, '/customer/v1/payment?page=', searchedText)
        .then((response) => {
          setRecordSet(response.content);
          setPage(response.page);
          setSize(response.size);
          setTotalElements(response.totalElements);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  //Load export as PDF records
  const loadPDFRecords = () => {
    getRecords('/customer/v1/payment/all').then((response) => {
      setPdfRecordSet(response);
    })
    .catch((error) => {
      notificationController.error({ message: 'Failed! ' + error.message });
    });

    const username = localStorage.getItem('username'); // Get username from local storage
    // Get user details by username
    getRecords(`/sa/users/${username}`).then((response) => {
      setAgencyName(response.agentName);
    })
    .catch((error) => {
      notificationController.error({ message: 'Failed! ' + error.message });
    });
  };

  useEffect(() => {
    loadRecords(searchedText, page, size);
    loadPDFRecords();
  }, [searchedText, page, size, loadRecords]);

  const save = () => navigate('/customer/payment/new');
  const edit = (id) => navigate(`/customer/payment/edit/${id}`);
  const view = (id) => navigate(`/customer/payment/edit/${id}`);

  const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);

  const data = recordSet.map((record, index) => ({
    key: index,
    paymentId: record.paymentId,
    customerName: record.customerName,
    mobileNo: record.mobileNo,
    packageName: record.packageName,
    serviceName: record.serviceName,
    paidAmount: record.amount,
    paymentModeName: record.paymentModeName,
    voucherNo: record.voucherNo,
    remarks: record.remarks,
    paymentDate: record.paymentDate,
  }));

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
      width: '20%',
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',
      key: 'mobileNo',
    },
    {
      title: 'Voucher No',
      dataIndex: 'voucherNo',
      key: 'voucherNo',
    },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentModeName',
      key: 'paymentModeName',
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
    },
    {
      title: 'Amount',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      render: (_, { paidAmount }) => {
        const color = paidAmount > 0 ? 'green' : 'blue';
        return (
          <Tag key={paidAmount} color={color} style={{ fontSize: 16, fontWeight: 600 }}>
            {'৳'.concat(' ').concat(numberFormat(paidAmount || 0))}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      render: (_, { paidAmount }) => (
        <Tag key={paidAmount} color={paidAmount > 0 ? 'green' : 'blue'} style={{ fontSize: 16, fontWeight: 600 }}>
          {paidAmount > 0 ? 'PAID' : 'REFUND'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton size="small" type="primary" onClick={() => view(record.paymentId)}>
            View
          </BaseButton>
          <BaseButton size="small" severity="info" onClick={() => edit(record.paymentId)}>
            Edit
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  //Download records as PDF section
  // Define title and sub-title
  const leftTitle = agencyName || 'Agency Name'; // Fallback if MyUnit is empty
  const rightTitle = 'Printed on:';
  const subTitle = 'Payment Realization';
  //Pdf report column
  const reportColumns = [
    'SL',
    'Group No',
    'Name',
    'Status',
    'Partner',
    'Package Price',
    'Realization',
    'Dues',
    'Payment Status',
  ];

  //Map pdfRecords
  const pdfRecords = pdfRecordSet.map((data, index) => [
    index + 1,
    data.groupNumber,
    data.customerName,
    data.customerStatus,
    data.partner,
    numberFormat(data.pkgPrice),
    numberFormat(data.paidAmount),
    numberFormat(data.dueAmount),
    data.dueAmount > 0 ? 'DUE' : 'PAID',
  ]);

  return (
    <div className="page-container">
      <S.Card
        id="basic-table"
        title="Payment List"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton type="info" className="btn btn-dark" onClick={save} size="small" icon={<PlusCircleOutlined />}>
            Add
          </BaseButton>
        }
      >
        <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onChange={(e) => setSearchedText(e.target.value)}
              onSearch={() => loadRecords(searchedText)}
              style={{
                width: 230,
                marginBottom: 10,
              }}
              allowClear
              size="small"
            />
          </ST.InputsWrapper>
          <div className="btn">
            <BaseSpace>
              <PDFDownloader 
                leftTitle={leftTitle}
                rightTitle={rightTitle}
                subTitle={subTitle}
                pdfRecordSet={pdfRecords}
                reportColumns={reportColumns}
                setTableLoading={setLoading} // Pass loading state
              />
            </BaseSpace>
          </div>
        </div>
        <BaseTable
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 10,
            total: totalElements,
            showTotal: (total) => (
              <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {total} records</span>
            ),
            onChange: (page) => loadRecords(searchedText, page - 1),
          }}
          rowKey={(record) => record.key}
          loading={loading}
          scroll={{ x: true }}
          bordered
        />
      </S.Card>
    </div>
  );
};

export default PaymentList;
