import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Row, Descriptions, Badge, Tag, Button, Input, Popconfirm, Card, Switch } from 'antd';
import { PlusCircleOutlined, ImportOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getRecordSet, getRecordById, saveAsRecord, updateGridRecord, getRecords } from '@app/utils/APIUtils';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput.styles';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import PDFDownloader from '@app/components/pdf-downloader/PDFDownloader';
import '../package/PackageList.css';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Text } from '@app/components/Error/Error.styles';

const PackageList = () => {
  const [recordSet, setRecordSet] = useState([]);
  const [agencyName, setAgencyName] = useState('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [viewRecord, setViewRecord] = useState(null);
  const [searchedText, setSearchedText] = useState('');
  const [page, setPage] = useState(0);
  const [editingKey, setEditingKey] = useState('');
  const [formData, setFormData] = useState({});
  const [priceGroupList, setPriceGroupList] = useState([]); // Initialize with your list
  const [priceGroupData, setPriceGroupData] = useState({}); // Holds updated values
  const [packageTypeItems] = useState([
    { value: 1, label: 'Shifting' },
    { value: 2, label: 'Non-Shifting' },
    { value: 3, label: 'Not Applicable' },
  ]);
  const [service, setService] = useState('Hajj'); // Default to "Hajj"
  const modalRef = useRef(null); // Reference for the modal content
  const navigate = useNavigate();
  const pageSize = 1000;

  const handleSwitchChange = (checked) => {
    const newService = checked ? 'Hajj' : 'Umrah'; // Switch toggles between Hajj and Umrah
    console.log("Switch changed to:", newService); // Log the value
    setService(newService);
    loadRecords(searchedText, page, pageSize, newService); // Explicitly pass newService
  };

  const loadRecords = useCallback(
    debounce((searchedText, page, pageSize, selectedService) => {
      setLoading(true);
      getRecordSet(page, pageSize, '/setup/v1/packages?page=', searchedText)
        .then((response) => {
          // Preprocess data
          //alert(service);
          const processedData = response
            .filter((record) => record.serviceName === selectedService) // Filter records where isPublisher is true
            .map((item) => ({
              ...item,
              priceGroupList:
                Array.isArray(item.categoryId) && Array.isArray(item.priceGroup)
                  ? item.categoryId.map((id, index) => ({
                      categoryId: id,
                      price: item.priceGroup[index],
                    }))
                  : [], // Return an empty array if they're not arrays
            }));

          setRecordSet(processedData);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  const fetchPricingGroupsData = async () => {
    setLoading(true);
    try {
      const [pricingGroups] = await Promise.all([getRecords('/domain/data/pricing/group/v1/list')]);

      // Define the custom order
      const customOrder = ['5-6 Pax/Room', '4-5 Pax/Room', '4 Pax/Room', '3 Pax/Room', '2 Pax/Room'];

      // Sort based on the custom order
      const sortedPricingGroups = pricingGroups.sort((a, b) => {
        return customOrder.indexOf(a.CategoryName) - customOrder.indexOf(b.CategoryName);
      });
      setPriceGroupList(sortedPricingGroups);
    } catch (error) {
      notificationController.error({
        message: `Failed to load data: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  //Load export as PDF records
  const loadPDFRecords = () => {
    const username = localStorage.getItem('username'); // Get username from local storage
    // Get user details by username
    getRecords(`/sa/users/${username}`)
      .then((response) => {
        setAgencyName(response.agentName);
      })
      .catch((error) => {
        notificationController.error({ message: 'Failed! ' + error.message });
      });
  };

  useEffect(() => {
    loadRecords(searchedText, page, pageSize, service);
    fetchPricingGroupsData();
    loadPDFRecords();
  }, [searchedText, loadRecords]);

  const save = () => navigate('/package/new');

  const importCsvFile = () => {
    navigate('/package/import/csv');
  };

  const handleSaveAs = (id) => {
    saveAsRecord('/setup/v1/packages/', id)
      .then((response) => {
        if (response.statusCode === 200) {
          notificationController.success({ message: 'Package created.' });
          setVisible(false);
          setEditingKey('');
          loadRecords(searchedText, page);
        }
      })
      .catch((error) => {
        notificationController.error({ message: 'Failed! ' + error.message });
      });
  };

  const handleView = (id) => {
    setVisible(true);
    getRecordById('/setup/v1/packages/view/', id).then((res) => {
      const priceGroupList = res.categoryId
        .map((id, index) => ({
          categoryId: id,
          price: res.priceGroup[index] || 0, // Use 0 if priceGroup[index] is undefined
        }))
        .sort((a, b) => a.categoryId - b.categoryId); // Sort by categoryId;
      setViewRecord({ ...res, priceGroupList });
    });
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);

  const isEditing = (record) => record.packageId === editingKey;

  const handleEdit = (record) => {
    setEditingKey(record.packageId);
    setFormData({ ...record }); // Clone the record to formData
  };

  const handleCancel = () => {
    setEditingKey('');
  };

  const handleInputChange = (key, value) => {
    console.log(`Updating ${key} to:`, value);
    setFormData((prev) => ({
      ...prev, // Spread the previous state to preserve existing values
      [key]: value, // Update only the specific field
    }));
  };

  const handlePriceGroupChange = (packageId, categoryId, value) => {
    setPriceGroupData((prev) => ({
      ...prev,
      [packageId]: {
        ...prev[packageId],
        [categoryId]: value,
      },
    }));
  };

  const handleGridDataSave = (packageId, packageName, price) => {
    const updatedPackage = priceGroupData[packageId] || {}; // Ensure fallback to empty object
    const payload = {
      packageId,
      packageName,
      price: Number(price),
      prices: Object.keys(updatedPackage).map((categoryId) => ({
        categoryId: Number(categoryId),
        price: Number(updatedPackage[categoryId]),
      })),
    };

    console.log('Payload for saving:', JSON.stringify(payload));

    updateGridRecord(payload, `/setup/v1/packages/${packageId}/gridedit`)
      .then(() => {
        notificationController.success({ message: 'Package updated.' });
        setEditingKey('');
        loadRecords(searchedText, page); // Reload the grid
      })
      .catch((error) => {
        notificationController.error({ message: 'Failed to update record: ' + error.message });
      });
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'key',
      key: 'key',
      width: '5%',
      render: (_, _record, index) => index + 1,
    },
    {
      title: 'Package Name',
      dataIndex: 'packageName',
      key: 'packageName',
      width: '15%',
      render: (text, record) => {
        return isEditing(record) ? (
          <Input
            value={formData.packageName || ''} // Ensure a fallback for undefined values
            onChange={(e) => {
              const newValue = e.target.value;
              handleInputChange('packageName', newValue);
            }}
          />
        ) : (
          text
        );
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      width: '10%',
      render: (_, record) => record.durationLabel + ' / ' + record.duration,
    },
    {
      title: 'Base Price',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (text, record) => {
        return isEditing(record) ? (
          <Input
            value={formData.price ?? 0} // Ensure a fallback for undefined values
            onChange={(e) => {
              const newValue = e.target.value;
              handleInputChange('price', newValue);
            }}
          />
        ) : (
          numberFormat(text)
        );
      },
    },
    // Dynamic columns for price groups
    ...(Array.isArray(priceGroupList) ? priceGroupList : []).map((group) => ({
      title: group.CategoryName,
      key: `category-${group.id}`,
      render: (_, record) => {
        const currentPrice =
          priceGroupData[record.packageId]?.[group.id] ??
          record.priceGroupList.find((p) => p.categoryId === group.id)?.price ??
          0;

        return isEditing(record) ? (
          <Input
            value={currentPrice}
            onChange={(e) => handlePriceGroupChange(record.packageId, group.id, e.target.value)}
          />
        ) : (
          numberFormat(currentPrice)
        );
      },
    })),
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <BaseSpace>
            <BaseButton
              size="small"
              type="primary"
              onClick={() =>
                handleGridDataSave(
                  record.packageId,
                  formData.packageName === null ? record.packageName : formData.packageName,
                  formData.price === null ? record.price : formData.price,
                )
              }
            >
              Save
            </BaseButton>
            <Popconfirm title="Are you sure to cancel?" onConfirm={handleCancel} placement="topRight">
              <BaseButton severity="info" size="small">
                Cancel
              </BaseButton>
            </Popconfirm>
          </BaseSpace>
        ) : (
          <BaseSpace>
            <BaseButton size="small" type="primary" onClick={() => handleView(record.packageId)}>
              View
            </BaseButton>
            <BaseButton size="small" onClick={() => navigate(`/package/edit/${record.packageId}`)}>
              Edit
            </BaseButton>
            <BaseButton severity="info" size="small" onClick={() => handleEdit(record)} hidden={editingKey !== ''}>
              Quick Edit
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  const handleExportPDFOnView = async () => {
    if (modalRef.current) {
      const modalElement = modalRef.current; // Reference to modal content

      // Use html2canvas to capture modal content
      const canvas = await html2canvas(modalElement, {
        scale: 2, // Higher scale for better resolution
      });
      const imgData = canvas.toDataURL('image/png');

      // Generate PDF
      const doc = new jsPDF('p', 'mm', 'a4'); // Portrait, millimeters, A4 size
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

      doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      doc.save('Package-details.pdf'); // Save PDF
    }
  };

  //Download records as PDF section
  // Define title and sub-title
  const leftTitle = agencyName || 'Agency Name'; // Fallback if MyUnit is empty
  const rightTitle = 'Printed on:';
  const subTitle = 'List of Packages';
  //Pdf report column
  const reportColumns = columns
    .filter((col) => col.title !== 'Action') // Exclude the "Action" column
    .map((col) => col.title);

  //Map pdfRecords
  const pdfRecords = recordSet
    .filter((record) => record.isPublished === true) // Filter records where isPublisher is true
    .map((record, index) => [
      index + 1,
      record.packageName,
      `${record.durationLabel} / ${record.duration}\n${record.shortDescription}`,
      numberFormat(record.price),
      ...priceGroupList.map((group) =>
        numberFormat(
          priceGroupData[record.packageId]?.[group.id] ??
            record.priceGroupList.find((p) => p.categoryId === group.id)?.price ??
            0,
        ),
      ),
    ]);

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title="Package List"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton type="info" className="btn btn-dark" onClick={save} size="small" icon={<PlusCircleOutlined />}>
            Add
          </BaseButton>
        }
      >
        <div
          className="search-box"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center', // Ensure vertical alignment
          }}
        >
          <BaseRow style={{ flex: 1 }}>
            <BaseCol>
              <BaseRow>
                <BaseSpace style={{ marginBottom: 10 }}>
                  <div>
                    <Text> Search: </Text>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <Switch
                      checked={service === 'Hajj'}
                      onChange={handleSwitchChange}
                      checkedChildren="Hajj"
                      unCheckedChildren="Umrah"
                      style={{
                        transform: 'scale(1.2)',
                        width: '80px',
                      }}
                      className={service === 'Hajj' ? 'switch-on' : 'switch-off'}
                    />
                  </div>
                </BaseSpace>
              </BaseRow>
              <BaseRow>
                <ST.InputsWrapper>
                  <SearchInput
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearchedText(e.target.value);
                      loadRecords(e.target.value);
                    }}
                    style={{
                      width: 230,
                      marginBottom: 10,
                    }}
                    allowClear
                    size="small"
                  />
                </ST.InputsWrapper>
              </BaseRow>
            </BaseCol>
          </BaseRow>
          <BaseCol style={{ marginLeft: 'auto' }}>
            <BaseRow></BaseRow>
            <BaseRow>
              <div className="importBtn" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
                <BaseSpace>
                  <PDFDownloader
                    leftTitle={leftTitle}
                    rightTitle={rightTitle}
                    subTitle={subTitle}
                    pdfRecordSet={pdfRecords}
                    reportColumns={reportColumns}
                    setTableLoading={setLoading} // Pass loading state
                  />
                  <Button
                    type="primary"
                    className="btn btnImport"
                    onClick={importCsvFile}
                    size="small"
                    icon={<ImportOutlined />}
                  >
                    Import
                  </Button>
                </BaseSpace>
              </div>
            </BaseRow>
          </BaseCol>
        </div>

        <BaseTable
          columns={columns}
          dataSource={recordSet}
          rowKey={(record) => record.packageId}
          pagination={{
            pageSize: pageSize,
            showTotal: (total) => (
              <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {total} records</span>
            ),
            onChange: (newPage) => setPage(newPage - 1),
          }}
          loading={loading}
          bordered
          scroll={{ x: true }}
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
      <Modal
        open={visible}
        onCancel={handleModalCancel}
        footer={
          <BaseSpace>
            <Button type="primary" size="small" onClick={() => handleSaveAs(viewRecord.packageId)}>
              Save As
            </Button>
            <Button type="primary" size="small" onClick={handleExportPDFOnView}>
              Export PDF
            </Button>
          </BaseSpace>
        }
        width={1000}
        style={{ top: 20 }}
        title="Package Details"
      >
        {viewRecord && (
          <div ref={modalRef}>
            <Row>
              <Card style={{ width: '100%' }}>
                <Descriptions bordered>
                  <Descriptions.Item label="ID">{viewRecord?.packageId}</Descriptions.Item>
                  <Descriptions.Item label="Created By">{viewRecord?.createdBy}</Descriptions.Item>
                  <Descriptions.Item label="Updated By">{viewRecord?.updatedBy}</Descriptions.Item>
                  <Descriptions.Item label="Record Version">{viewRecord?.version}</Descriptions.Item>
                  <Descriptions.Item label="Created At">{viewRecord?.createdAt}</Descriptions.Item>
                  <Descriptions.Item label="Updated At">{viewRecord?.updatedAt}</Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <Badge
                      status={viewRecord?.recordStatus === 1 ? 'success' : 'error'}
                      text={viewRecord?.recordStatus === 1 ? 'ACTIVE' : 'IN-ACTIVE'}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Published">
                    <Badge
                      status={viewRecord?.isPublished === true ? 'success' : 'error'}
                      text={viewRecord?.isPublished === true ? 'YES' : 'NO'}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Card>

              <Card style={{ width: '100%', marginTop: 20 }}>
                <Descriptions bordered>
                  <Descriptions.Item label="Package">{viewRecord?.serviceName}</Descriptions.Item>
                  <Descriptions.Item label="Package Name">{viewRecord?.packageName}</Descriptions.Item>
                  <Descriptions.Item label="Package Duration">{viewRecord?.duration}</Descriptions.Item>
                  <Descriptions.Item label="Short Descriptions" span={3}>
                    {viewRecord?.shortDescription}
                  </Descriptions.Item>
                  <Descriptions.Item label="Details" span={3}>
                    {viewRecord?.packageDetails}
                  </Descriptions.Item>
                  <Descriptions.Item label="Price Range">
                    <Tag color="green">{viewRecord?.priceRange}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Base Price">
                    <Tag color="green">{'৳ ' + new Intl.NumberFormat('en-IN').format(viewRecord?.price)}</Tag>
                  </Descriptions.Item>
                  {Array.isArray(viewRecord?.priceGroupList) && viewRecord.priceGroupList.length > 0 ? (
                    viewRecord.priceGroupList
                      .filter((group) => group.price !== 0) // Filter records where price is not 0
                      .sort((a, b) => {
                        // Find category names based on categoryId
                        const categoryA =
                          priceGroupList.find((c) => c.id === a.categoryId)?.CategoryName || `Category ${a.categoryId}`;
                        const categoryB =
                          priceGroupList.find((c) => c.id === b.categoryId)?.CategoryName || `Category ${b.categoryId}`;

                        // Define the custom order
                        const customOrder = ['5-6 Pax/Room', '4-5 Pax/Room', '4 Pax/Room', '3 Pax/Room', '2 Pax/Room'];

                        // Sort based on custom order
                        const indexA = customOrder.indexOf(categoryA);
                        const indexB = customOrder.indexOf(categoryB);

                        // Handle categories not in custom order by placing them at the end
                        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
                      })
                      .map((group) => {
                        const category =
                          priceGroupList.find((c) => c.id === group.categoryId)?.CategoryName ||
                          `Category ${group.categoryId}`;
                        return (
                          <Descriptions.Item label={category} key={group.categoryId}>
                            <Tag color="green">{'৳ ' + numberFormat(group.price)}</Tag>
                          </Descriptions.Item>
                        );
                      })
                  ) : (
                    <Descriptions.Item label="No Pricing Data">N/A</Descriptions.Item>
                  )}

                  <Descriptions.Item label="Makkah Hotel">{viewRecord?.makkahHotel}</Descriptions.Item>
                  <Descriptions.Item label="Madinah Hotel">{viewRecord?.madinahHotel}</Descriptions.Item>
                  <Descriptions.Item label="Package Type">
                    {packageTypeItems.find((item) => item.value === viewRecord?.packageTypeId)?.label || ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="VIP Tent Charge">
                    <Tag color="green">{'৳ ' + new Intl.NumberFormat('en-IN').format(viewRecord?.vipTentCharge)}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Special Bus Charge">
                    <Tag color="green">
                      {'৳ ' + new Intl.NumberFormat('en-IN').format(viewRecord?.specialBusCharge)}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Meals (Included)">
                    {viewRecord?.isMealsIncluded === true ? 'YES' : 'NO'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Start Date">
                    {viewRecord.startDate ? moment(viewRecord?.startDate).format('YYYY-MM-DD') : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="End Date">
                    {viewRecord?.endDate ? moment(viewRecord?.endDate).format('YYYY-MM-DD') : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Remarks">{viewRecord?.remarks}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Row>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PackageList;
