import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
//import LoginPage from '@app/pages/LoginPage';

import Login from '@app/components/auth/LoginForm/Login';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
//import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import HajlinkDashboardPage from '@app/pages/DashboardPages/HajlinkDashboardPage';
import { notificationController } from '@app/controllers/notificationController';
import { deleteItemByParam, getCurrentUser } from 'utils/APIUtils';
import ServiceList from '@app/web/setup/service/ServiceList';
import AddService from '@app/web/setup/service/AddService';
import { EditService } from '@app/web/setup/service/EditService';
import AgentList from '@app/web/agent/AgentList';
import AddAgent from '@app/web/agent/AddAgent';
import EditAgent from '@app/web/agent/EditAgent';
import PackageList from '@app/web/setup/package/PackageList';
import AddPackage from '@app/web/setup/package/AddPackage';
import ActivityList from '@app/web/activity/ActivityList';
import AddActivity from '@app/web/activity/AddActivity';
import EditActivity from '@app/web/activity/EditActivity';
import CustomerList from '@app/web/customer/CustomerList';
import AddCustomer from '@app/web/customer/AddCustomer';
import EditCustomer from '@app/web/customer/EditCustomer';
import CustomerReport from '@app/web/customer/rpt/CustomerReport';
import PreRegistrationList from '@app/web/customer/pregistration/PreRegistrationList';
import AddPreRegistration from '@app/web/customer/pregistration/AddPreRegistration';
import EditPreRegistration from '@app/web/customer/pregistration/EditPreRegistration';
import RegistrationList from '@app/web/customer/registration/RegistrationList';
import AddRegistration from '@app/web/customer/registration/AddRegistration';
import EditRegistration from '@app/web/customer/registration/EditRegistration';
import PackagePreferencesList from '@app/web/setup/pkgpreference/PackagePreferencesList';
import AddPackagePreferences from '@app/web/setup/pkgpreference/AddPackagePreferences';
import EditPackagePreferences from '@app/web/setup/pkgpreference/EditPackagePreferences';
import CustomerSearch from '@app/web/customer/CustomerSearch';
import Customer360View from '@app/web/customer/Customer360View';
import PaymentList from '@app/web/customer/payment/PaymentList';
import AddPayment from '@app/web/customer/payment/AddPayment';
import EditPayment from '@app/web/customer/payment/EditPayment';
import HotelList from '@app/web/setup/hotel/HotelList';
import AddHotel from '@app/web/setup/hotel/AddHotel';
import RoomList from '@app/web/setup/room/RoomList';
import EditHotel from '@app/web/setup/hotel/EditHotel';
import CustomerPackagesList from '@app/web/customer/package/CustomerPackageList';
import CustomerPackageAdd from '@app/web/customer/package/CustomerPackageAdd';
import CustomerPackageEdit from '@app/web/customer/package/CustomerPackageEdit';
import AddRoom from '@app/web/setup/room/AddRoom';
import EditRoom from '@app/web/setup/room/EditRoom';
import AddCustomerMoreInfo from '@app/web/customer/AddCustomerMoreInfo';
import DocumentList from '@app/web/customer/doc/CustomerDocumentList';
import AddDocument from '@app/web/customer/doc/AddCustomerDocument';
import EditDocument from '@app/web/customer/doc/EditCustomerDocument';
import CustomerRoomList from '@app/web/customer/room/CustomerRoomList';
import CustomerRoomAdd from '@app/web/customer/room/CustomerRoomAdd';
import CustomerRoomEdit from '@app/web/customer/room/CustomerRoomEdit';
import BusList from '@app/web/setup/bus/BusList';
import AddBus from '@app/web/setup/bus/AddBus';
import EditBus from '@app/web/setup/bus/EditBus';
import CustomerBusList from '@app/web/customer/bus/CustomerBusList';
import CustomerBusAdd from '@app/web/customer/bus/CustomerBusAdd';
import CustomerBusEdit from '@app/web/customer/bus/CustomerBusEdit';
import FlightList from '@app/web/setup/flight/FlightList';
import AddFlight from '@app/web/setup/flight/AddFlight';
import EditFlight from '@app/web/setup/flight/EditFlight';
import CustomerFlightList from '@app/web/customer/flight/CustomerFlightList';
import CustomerFlightAdd from '@app/web/customer/flight/CustomerFlightAdd';
import CustomerFlightEdit from '@app/web/customer/flight/CustomerFlightEdit';
import PregistrationReport from '@app/web/customer/pregistration/rpt/PregistrationReport';
import RegistrationReport from '@app/web/customer/registration/rpt/RegistrationReport';
import CustomerPkgReport from '@app/web/customer/package/rpt/CustomerPkgReport';
import CustomerHotelReport from '@app/web/setup/hotel/rpt/CustomerHotelReport';
import CustomerBusReport from '@app/web/customer/bus/rpt/CustomerBusReport';
import CustomerFlightReport from '@app/web/customer/flight/rpt/CustomerFlightReport';
import CustomerDocReport from '@app/web/customer/doc/rpt/CustomerDocReport';
import ExpenseList from '@app/web/setup/expenditure/ExpenseList';
import AddExpense from '@app/web/setup/expenditure/AddExpense';
import EditExpense from '@app/web/setup/expenditure/EditExpense';
import PackageReport from '@app/web/setup/package/rpt/PackageReport';
import CustomerIdCard from '@app/web/customer/idCard/CustomerIdCard';
import CollectionList from '@app/web/customer/collection/CollectionList';
import AddCollection from '@app/web/customer/collection/AddCollection';
import EditCollection from '@app/web/customer/collection/EditCollection';
import ImportBusRecord from '@app/web/setup/bus/ImportBusRecord';
import ImportFlightRecord from '@app/web/setup/flight/ImportFlightRecord';
import ImportPackageRecord from '@app/web/setup/package/ImportPackageRecord';
import ImportHotelRecord from '@app/web/setup/hotel/ImportHotelRecord';
import CategoryList from '@app/web/category/CategoryList';
import AddCategory from '@app/web/category/AddCategory';
import EditCategory from '@app/web/category/EditCategory';
import CustomerUploadedDocReport from '@app/web/customer/doc/rpt/CustomerUploadedDocReport';
import ImportCustomerRecord from '@app/web/customer/ImportCustomerRecord';
import ImportPreRegistrationRecord from '@app/web/customer/pregistration/ImportPreRegistrationRecord';
import { ACCESS_TOKEN } from '@app/constants';
import EditPackage from '@app/web/setup/package/EditPackage';
import ImportRegistrationRecord from '@app/web/customer/registration/ImportRegistrationRecord';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const AlliancesPage = React.lazy(() => import('@app/pages/AlliancesPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const AUTH_PATH = '/auth';
export const BASE_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/hajlink-dashboard';

const HajlinkDashboard = withLoading(HajlinkDashboardPage);
//const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Alliances = withLoading(AlliancesPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

function AppRouter() {
  const [datapath, setDatapath] = useState('');
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const navigate = useNavigate();

  const loadCurrentUser = useCallback(async () => {
    try {
      const response = await getCurrentUser();
      setDatapath(response.datapath);
      localStorage.setItem("username", response.username);
    } catch (error) {
      
    }
  }, []);

  const logout = useCallback(
    async (
      redirectTo = '/auth/login', // Default redirect URL
      notificationType = 'success', // Default notification type
      message = "You're logged out.", // Default message
    ) => {
      const username = localStorage.getItem("username");
      if (username !== null) {
        // Ensure username exists
        try {
          const res = await deleteItemByParam(`/auth/logout?username=${username}`);
          if (res.statusCode === 200) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem("username");
            navigate(redirectTo);
            notificationController[notificationType]({
              message: message,
            });
          }
        } catch (error) {
          notificationController.error({
            message: error.message,
          });
        }
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (!localStorage.getItem(ACCESS_TOKEN) && !isLoggedOut) {
      setIsLoggedOut(true); // Prevent further calls
      logout('/auth/login', 'warning', 'Session expired. Login again.');
    } else {
      loadCurrentUser();
    }
  }, [isLoggedOut, logout, loadCurrentUser]);

  const handleLogin = useCallback(() => {
    loadCurrentUser();
    notificationController.success({
      message: "You're logged in.",
    });
  }, [loadCurrentUser]);

  const protectedLayout = (
    <RequireAuth>
      <MainLayout datapath={datapath} />
    </RequireAuth>
  );

  return (
    <Routes>
      <Route path={BASE_PATH} element={protectedLayout}>
        {/* <Route path="dashboard" element={<NftDashboard />} />
            <Route path={MEDICAL_DASHBOARD_PATH} element={<HajlinkDashboard />} /> */}
        <Route path="dashboard" element={<HajlinkDashboard />} />

        {/* Category */}
        <Route path="category/list" element={<CategoryList />} />
        <Route path="category/new" element={<AddCategory />} />
        <Route path="category/edit/:id" element={<EditCategory />} />

        {/* Service */}
        <Route path="service/list" element={<ServiceList />} />
        <Route path="service/new" element={<AddService />} />
        <Route path="service/edit/:id" element={<EditService />} />

        {/* Agent */}
        <Route path="agent/list" element={<AgentList />} />
        <Route path="agent/new" element={<AddAgent />} />
        <Route path="agent/edit/:id" element={<EditAgent />} />

        {/* Package */}
        <Route path="package/list" element={<PackageList />} />
        <Route path="package/new" element={<AddPackage />} />
        <Route path="package/edit/:id" element={<EditPackage />} />
        <Route path="package/import/csv" element={<ImportPackageRecord />} />

        {/* Activity */}
        <Route path="activity/list" element={<ActivityList />} />
        <Route path="activity/new" element={<AddActivity />} />
        <Route path="activity/edit/:id" element={<EditActivity />} />

        {/* Customer */}
        <Route path="customer/list" element={<CustomerList />} />
        <Route path="customer/new" element={<AddCustomer />} />
        <Route path="customer/edit/:id" element={<EditCustomer />} />
        <Route path="customer/profile/new/:id" element={<AddCustomerMoreInfo />} />
        <Route path="customer/import/csv" element={<ImportCustomerRecord />} />

        <Route path="customer/search" element={<CustomerSearch />} />

        <Route path="customer/360/view/:id" element={<Customer360View />} />

        {/* Customer -> Pre-registration */}
        <Route path="pre_registration/list" element={<PreRegistrationList />} />
        <Route path="pre_registration/new" element={<AddPreRegistration />} />
        <Route path="pre_registration/edit/:id" element={<EditPreRegistration />} />
        <Route path="pre_registration/import/csv" element={<ImportPreRegistrationRecord />} />

        {/* Customer -> Registartion */}
        <Route path="registration/list" element={<RegistrationList />} />
        <Route path="registration/new" element={<AddRegistration />} />
        <Route path="registration/edit/:id" element={<EditRegistration />} />
        <Route path="registration/import/csv" element={<ImportRegistrationRecord />} />

        {/* Package preferences */}
        <Route path="package_preferences/list" element={<PackagePreferencesList />} />
        <Route path="package_preferences/new" element={<AddPackagePreferences />} />
        <Route path="package_preferences/edit/:id" element={<EditPackagePreferences />} />

        {/* Collection */}
        <Route path="customer/payment/collection/list" element={<CollectionList />} />
        <Route path="customer/payment/collection/new" element={<AddCollection />} />
        <Route path="customer/payment/collection/edit/:id" element={<EditCollection />} />

        {/* Customer -> Payment */}
        <Route path="customer/payment/list" element={<PaymentList />} />
        <Route path="customer/payment/new" element={<AddPayment />} />
        <Route path="customer/payment/edit/:id" element={<EditPayment />} />

        {/* Hotel */}
        <Route path="hotel/list" element={<HotelList />} />
        <Route path="hotel/new" element={<AddHotel />} />
        <Route path="hotel/edit/:id" element={<EditHotel />} />
        <Route path="hotel/import/csv" element={<ImportHotelRecord />} />

        {/* Room */}
        <Route path="room/list" element={<RoomList />} />
        <Route path="room/new/:id" element={<AddRoom />} />
        <Route path="room/new" element={<AddRoom />} />
        <Route path="room/edit/:id" element={<EditRoom />} />

        {/* Customers -> Package */}
        <Route path="m_customer_package/list" element={<CustomerPackagesList />} />
        <Route path="m_customer_package/new" element={<CustomerPackageAdd />} />
        <Route path="m_customer_package/edit/:id" element={<CustomerPackageEdit />} />

        {/* Customers -> Room */}
        <Route path="assign_customer_room/list" element={<CustomerRoomList />} />
        <Route path="assign_customer_room/new" element={<CustomerRoomAdd />} />
        <Route path="assign_customer_room/edit/:id" element={<CustomerRoomEdit />} />

        {/* Customer -> Documnets */}
        <Route path="customer/doc/list" element={<DocumentList />} />
        <Route path="customer/doc/new" element={<AddDocument />} />
        <Route path="customer/doc/edit/:id" element={<EditDocument />} />

        {/* Bus */}
        <Route path="bus/import/csv" element={<ImportBusRecord />} />
        <Route path="bus/list" element={<BusList />} />
        <Route path="bus/new" element={<AddBus />} />
        <Route path="bus/edit/:id" element={<EditBus />} />

        {/* Customers - Bus */}
        <Route path="assign_customer_bus/list" element={<CustomerBusList />} />
        <Route path="assign_customer_bus/new" element={<CustomerBusAdd />} />
        <Route path="assign_customer_bus/edit/:id" element={<CustomerBusEdit />} />

        {/* Flight */}
        <Route path="flight/list" element={<FlightList />} />
        <Route path="flight/new" element={<AddFlight />} />
        <Route path="flight/edit/:id" element={<EditFlight />} />
        <Route path="flight/import/csv" element={<ImportFlightRecord />} />

        {/* Customer -> Flight */}
        <Route path="assign_customer_flight/list" element={<CustomerFlightList />} />
        <Route path="assign_customer_flight/new" element={<CustomerFlightAdd />} />
        <Route path="assign_customer_flight/edit/:id" element={<CustomerFlightEdit />} />

        {/* Expense */}
        <Route path="expense/list" element={<ExpenseList />} />
        <Route path="expense/new" element={<AddExpense />} />
        <Route path="expense/edit/:id" element={<EditExpense />} />

        {/* Id card generator */}
        <Route path="customer/generate/idcard" element={<CustomerIdCard />} />

        {/* Reports */}
        <Route path="customer/details/report" element={<CustomerReport />} />
        <Route path="customer/pregistration/report" element={<PregistrationReport />} />
        <Route path="customer/registration/report" element={<RegistrationReport />} />
        <Route path="customer/package/report" element={<CustomerPkgReport />} />
        <Route path="customer/hotel/report" element={<CustomerHotelReport />} />
        <Route path="customer/bus/report" element={<CustomerBusReport />} />
        <Route path="customer/flight/report" element={<CustomerFlightReport />} />
        <Route path="customer/roomsticker/report" element={<CustomerReport />} />
        <Route path="customer/passportsticker/report" element={<CustomerReport />} />
        <Route path="customer/document/report" element={<CustomerDocReport />} />
        <Route path="customer/uploaded/document/report" element={<CustomerUploadedDocReport />} />
        <Route path="setup/package/report" element={<PackageReport />} />

        <Route path="apps">
          <Route path="feed" element={<NewsFeed />} />
        </Route>
        <Route path="forms">
          <Route path="advanced-forms" element={<AdvancedForm />} />
        </Route>
        <Route path="data-tables" element={<DataTables />} />
        <Route path="charts" element={<Charts />} />
        <Route path="maps">
          <Route path="google-maps" element={<Google />} />
          <Route path="leaflet-maps" element={<Leaflet />} />
          <Route path="react-simple-maps" element={<ReactSimple />} />
          <Route path="pigeon-maps" element={<Pigeons />} />
        </Route>
        <Route path="server-error" element={<ServerError />} />
        <Route path="404" element={<Error404 />} />

        <Route path="profile" element={<ProfileLayout />}>
          <Route path="personal-info/:username" element={<PersonalInfo />} />
          <Route path="security-settings/:username" element={<SecuritySettings />} />
          <Route path="status/:username" element={<Notifications />} />
          <Route path="alliances/:username" element={<Alliances />} />
        </Route>
        <Route path="ui-components">
          <Route path="button" element={<Buttons />} />
          <Route path="spinner" element={<Spinners />} />
          <Route path="input" element={<Inputs />} />
          <Route path="checkbox" element={<Checkboxes />} />
          <Route path="radio" element={<Radios />} />
          <Route path="select" element={<Selects />} />
          <Route path="switch" element={<Switches />} />
          <Route path="upload" element={<Uploads />} />
          <Route path="rate" element={<Rates />} />
          <Route path="auto-complete" element={<AutoCompletes />} />
          <Route path="steps" element={<Steps />} />
          <Route path="date-time-picker" element={<DateTimePickers />} />
          <Route path="dropdown" element={<Dropdowns />} />
          <Route path="breadcrumbs" element={<Breadcrumbs />} />
          <Route path="tabs" element={<Tabs />} />
          <Route path="avatar" element={<Avatars />} />
          <Route path="badge" element={<Badges />} />
          <Route path="collapse" element={<Collapse />} />
          <Route path="pagination" element={<Pagination />} />
          <Route path="modal" element={<Modals />} />
          <Route path="popover" element={<Popovers />} />
          <Route path="popconfirm" element={<Popconfirms />} />
          <Route path="progress" element={<Progress />} />
          <Route path="result" element={<Results />} />
          <Route path="alert" element={<Alerts />} />
          <Route path="notification" element={<NotificationsUI />} />
          <Route path="skeleton" element={<Skeletons />} />
        </Route>
      </Route>
      <Route path={AUTH_PATH} element={<AuthLayoutFallback />}>
        <Route path="login" element={<Login onLogin={handleLogin} />} />

        <Route path="sign-up" element={<SignUpPage />} />
        <Route
          path="lock"
          element={
            <RequireAuth>
              <LockPage />
            </RequireAuth>
          }
        />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="security-code" element={<SecurityCodePage />} />
        <Route path="new-password/:username" element={<NewPasswordPage />} />
      </Route>
      <Route path="/logout" element={<LogoutFallback />} />
    </Routes>
  );
}
export default AppRouter;
