import React, { Component, useState } from 'react';
import { getRecordById, deleteItemByID, getRecordSet, saveAsRecord } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions, Badge } from 'antd';
import { PlusCircleOutlined, ImportOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import { Button } from '@app/components/common/BaseButton/BaseButton.styles';
import moment from 'moment';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <HotelList {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} navigate={navigate} />
  );
}

class HotelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      visible: false,
      viewRecord: [],
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.importHotelCsvFile = this.importHotelCsvFile.bind(this);
  }

  loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/setup/v1/hotels?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/hotel/new');
  }

  handleSaveAs(id, event) {
    event.preventDefault();

    saveAsRecord('/setup/v1/hotels/', id)
      .then((response) => {
        if (response.statusCode === 200) {
          notificationController.success({
            message: 'Hotel created.',
          });
          this.setState({
            visible: false,
          });
          this.loadRecords();
          this.props.navigate('/hotel/list');
          //window.location.reload();
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  importHotelCsvFile() {
    this.props.navigate('/hotel/import/csv');
  }

  edit(id) {
    this.props.navigate(`/hotel/edit/${id}`);
  }

  delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/setup/v1/hotels/')
            .then((res) => {
              if (res.statusCode === 110) {
                notificationController.warning({
                  message: 'Mapped hotel (' + id + ") with room couldn't delete",
                });
                this.props.navigate('/hotel/list/');
              } else {
                this.setState({ recordSet: this.state.recordSet.filter((record) => record.hotelId !== id) });
                notificationController.success({
                  message: 'Hotel deleted',
                });
                this.props.navigate('/hotel/list/');
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  }

  view(id) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecordById('/setup/v1/hotels/', id);
    promise1.then((res) => {
      this.setState({
        viewRecord: res,
        distance: res.DistanceBean.Distance,
        location: res.LocationBean.LocName,
      });
    }, []);

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  addRoom(id) {
    this.props.navigate(`/room/new/${id}`);
  }

  render() {
    const { recordSet, totalElements, viewRecord, distance, location } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        hotelId: record.hotelId,
        location: record.locationName,
        distance: record.distance,
        hotelName: record.hotelName,
        hotelType: record.hotelType,
        remarks: record.remarks,
      });
    });

    const columns = [
      {
        title: 'Hotel Name',
        dataIndex: 'hotelName',
        key: 'hotelName',
        width: '20%',
        /* filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.hotelName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.hotelType).toLowerCase().includes(value.toLowerCase()) ||
            String(record.location).toLowerCase().includes(value.toLowerCase()) ||
            String(record.packageName).toLowerCase().includes(value.toLowerCase())
          );
        }, */
      },
      {
        title: 'Hotel Type',
        dataIndex: 'hotelType',
        key: 'hotelType',
        width: '15%',
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: '10%',
      },
      {
        title: 'Distance',
        dataIndex: 'distance',
        key: 'distance',
        width: '10%',
      },
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '15%',
      },
      {
        title: 'Room',
        dataIndex: 'hotelId',
        width: '10%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.addRoom(record.hotelId)}>
                Add Room
              </BaseButton>
            </BaseSpace>
          );
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: '15%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.hotelId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.hotelId)}>
                Edit
              </BaseButton>

              <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.hotelId)}>
                Delete
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Hotel List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
            <div className="importBtn">
              <Button
                type="primary"
                className="btn btnImport"
                onClick={this.importHotelCsvFile}
                size="small"
                icon={<ImportOutlined />}
              >
                Import
              </Button>
            </div>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(this.state.searchedText, page - 1),
              //showSizeChanger: true,
              //pageSizeOptions: ['10', '100'],
            }}
            rowKey={(record) => record.key}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>
        <Modal
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={1000}
          style={{ top: 20 }}
          footer={[
            <Button
              key="saveAs"
              size="small"
              type="primary"
              style={{ display: 'inline' }}
              onClick={(e) => this.handleSaveAs(viewRecord.id, e)}
            >
              Save As
            </Button>,
          ]}
        >
          <Row>
            <Descriptions title="Hotel Info" layout="vertical" bordered size="middle">
              <Descriptions.Item label="Hotel Id">{viewRecord.id}</Descriptions.Item>
              <Descriptions.Item label="Hotel Name">{viewRecord.HotelName}</Descriptions.Item>
              <Descriptions.Item label="Hotel Type">{viewRecord.HotelType}</Descriptions.Item>
              <Descriptions.Item label="Location">{location}</Descriptions.Item>
              <Descriptions.Item label="Distance">{distance}</Descriptions.Item>
              <Descriptions.Item label="Published">{viewRecord.IsPublished === true ? 'YES' : 'NO'}</Descriptions.Item>
              <Descriptions.Item label="Cascade">{viewRecord.IsCascade === true ? 'YES' : 'NO'}</Descriptions.Item>
              <Descriptions.Item label="Record Status">
                <Badge status="success" text={viewRecord.status === 1 ? 'Active' : 'Inactive'} />
              </Descriptions.Item>
              <Descriptions.Item label="Created Date" time>
                {moment(viewRecord.createdAt).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Updated Date" span={2}>
                {moment(viewRecord.updatedAt).format('YYYY-MM-DD')}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Modal>
      </div>
    );
  }
}
