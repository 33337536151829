import { API_BASE_URL, POLL_LIST_SIZE, LIST_SIZE, ACCESS_TOKEN } from '../constants';

const request = async (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  const defaults = { headers };
  const finalOptions = { ...defaults, ...options };

  try {
    const response = await fetch(finalOptions.url, finalOptions);

    if (response.status === 401) {
      // Handle session timeout or unauthorized access
      localStorage.removeItem(ACCESS_TOKEN);

      // Avoid infinite redirects
      if (window.location.pathname !== '/backend/auth/login') {
        window.location.href = '/backend/auth/login';
      }

      throw new Error('Unauthorized: Session has expired');
    }

    if (!response.ok) {
      const errorResponse = await response.json();
      throw errorResponse;
    }

    return await response.json(); // Process successful response
  } catch (error) {
    console.error('API Request Error:', error);

    // Optional: Handle generic error messaging
    if (!error.message) {
      error.message = 'An unexpected error occurred. Please try again later.';
    }

    throw error;
  }
};

export function getAllPolls(page, size) {
  page = page || 0;
  size = size || POLL_LIST_SIZE;

  return request({
    url: API_BASE_URL + '/polls?page=' + page + '&size=' + size,
    method: 'GET',
  });
}

export function createPoll(pollData) {
  return request({
    url: API_BASE_URL + '/polls',
    method: 'POST',
    body: JSON.stringify(pollData),
  });
}

export function castVote(voteData) {
  return request({
    url: API_BASE_URL + '/polls/' + voteData.pollId + '/votes',
    method: 'POST',
    body: JSON.stringify(voteData),
  });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + '/auth/login',
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
    },
    body: loginRequest,
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + '/sa/users/signup',
    method: 'POST',
    body: JSON.stringify(signupRequest),
  });
}

export function getCaptcha(endpoints) {
  return request({
    url: API_BASE_URL + endpoints,
    mode: 'cors',
    method: 'GET',
  });
}

/* export function tokenRefresh(refreshTokenRequest) {
  refreshTokenRequest = refreshTokenRequest || '';
  return request({
    url: API_BASE_URL + '/auth/refreshtoken',
    method: 'POST',
    body: JSON.stringify(refreshTokenRequest),
  });
} */

export function checkUsernameAvailability(username) {
  return request({
    url: API_BASE_URL + '/sa/users/checkUsername?username=' + username,
    method: 'GET',
  });
}

export function checkEmailAvailability(email) {
  return request({
    url: API_BASE_URL + '/sa/users/checkEmail?email=' + email,
    method: 'GET',
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject('No access token set.');
  }

  return request({
    url: API_BASE_URL + '/sa/users/me',
    method: 'GET',
  });
}

export function getUserProfile(endpoint, username) {
  return request({
    url: API_BASE_URL + endpoint + username,
    method: 'GET',
  });
}

export function getUserCreatedPolls(username, page, size) {
  page = page || 0;
  size = size || POLL_LIST_SIZE;

  return request({
    url: API_BASE_URL + '/users/' + username + '/polls?page=' + page + '&size=' + size,
    method: 'GET',
  });
}

export function getUserVotedPolls(username, page, size) {
  page = page || 0;
  size = size || POLL_LIST_SIZE;

  return request({
    url: API_BASE_URL + '/users/' + username + '/votes?page=' + page + '&size=' + size,
    method: 'GET',
  });
}

export function saveRecord(saveRequest, endpoint) {
  return request({
    url: API_BASE_URL + endpoint,
    method: 'POST',
    body: JSON.stringify(saveRequest),
  });
}

export function saveFormRecord(saveRequest, endpoint) {
  return request({
    url: API_BASE_URL + endpoint,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
    },
    body: saveRequest,
  });
}

export function saveAsRecord(endpoint, id) {
  return request({
    url: API_BASE_URL + endpoint + id,
    method: 'POST',
  });
}

export function updateFormData(saveRequest, endpoint) {
  return request({
    url: API_BASE_URL + endpoint,
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
    },
    body: saveRequest,
  });
}

export function searchRecord(searchRequest, endpoint) {
  return request({
    url: API_BASE_URL + endpoint,
    method: 'POST',
    body: JSON.stringify(searchRequest),
  });
}

export function getRecordSet(page, size, endpoints, searchedText) {
  page = page || 0;
  size = size || LIST_SIZE;
  searchedText = searchedText || '';

  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject('No access token set.');
  }

  return request({
    url: API_BASE_URL + endpoints + page + '&size=' + size + '&searchedText=' + searchedText,
    mode: 'cors',
    method: 'GET',
  });
}

export function getRecords(endpoints) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject('No access token set.');
  }
  return request({
    url: API_BASE_URL + endpoints,
    mode: 'cors',
    method: 'GET',
  });
}

export function getSearchRecords(endpoints, query) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject('No access token set.');
  }
  return request({
    url: API_BASE_URL + endpoints + query,
    mode: 'cors',
    method: 'POST',
  });
}

export function getRecordById(endpoints, id) {
  return request({
    url: API_BASE_URL + endpoints + id,
    method: 'GET',
    mode: 'cors',
  });
}

export function updateRecord(id, updateRequest, endpoints) {
  return request({
    url: API_BASE_URL + endpoints + id,
    mode: 'cors',
    method: 'PUT',
    body: JSON.stringify(updateRequest),
  });
}

export function updateFormRecord(id, updateRequest, endpoints) {
  return request({
    url: API_BASE_URL + endpoints + id,
    method: 'PUT',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
    },
    body: updateRequest,
  });
}

export function updateGridRecord(updateRequest, endpoints) {
  return request({
    url: API_BASE_URL + endpoints,
    mode: 'cors',
    method: 'PUT',
    body: JSON.stringify(updateRequest),
  });
}

export function deleteItemByID(id, endpoints) {
  return request({
    url: API_BASE_URL + endpoints + id,
    method: 'DELETE',
  });
}

export function deleteItemByParam(endpoints) {
  return request({
    url: API_BASE_URL + endpoints,
    method: 'DELETE',
  });
}

export function checkRecordAvailability(endpoints, requestParam) {
  return request({
    url: API_BASE_URL + endpoints + requestParam,
    method: 'GET',
  });
}

/* export function checkAgentEmailAvailability(email) {
    return request({
        url: API_BASE_URL + "/setup/agent/checkAgentEmail?email=" + email,
        method: 'GET'
    });
} */
